import AppLayout from "components/AppLayout";
import ConstrainedRoute from "components/ConstrainedRoute";
import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";

import { Auth } from "./Auth";
import { ClientDetails } from "./ClientDetails";
import { Clients } from "./Clients";
import { Company } from "./Company";
import { EmployeeDetails } from "./EmployeeDetails";
import Employees from "./Employees";
import { Finances } from "./Finances";
import Home from "./Home";
import { LocationDetails } from "./LocationDetails";
import Locations from "./Locations";
import NewClient from "./NewClient";
import NewEmployee from "./NewEmployee";
import NewLocation from "./NewLocation";
import { Onboarding } from "./Onboarding";
import { Profile } from "./Profile";
import { QuoteEditor } from "./QuoteEditor";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Hello = () => <p>hello</p>;

export const Routes = () => {
  return (
    <Router>
      <Switch>
        <ConstrainedRoute onlyVisitors path="/auth">
          <Switch>
            <Redirect exact path="/auth" to="/auth/signup" />
            <Route path="/auth/signup" component={Auth} />
          </Switch>
        </ConstrainedRoute>
        <ConstrainedRoute path="/onboarding">
          <Redirect exact path="/onboarding" to="/onboarding/profile" />
          <Route path="/onboarding" component={Onboarding} />
        </ConstrainedRoute>
        <ConstrainedRoute path="/" needsOnboarding>
          <Switch>
            <Route exact path="/me" component={Profile} />
            <Route exact path="/company/:id" component={Company} />
            <Route exact path="/company" component={Company} />
            <Route path="/new">
              <Route exact path="/new/employees" component={NewEmployee} />
              <Route exact path="/new/locations" component={NewLocation} />
              <Route exact path="/new/clients" component={NewClient} />
              <Route exact path="/new/quotes" component={QuoteEditor} />
            </Route>
            <Route path="/edit">
              <Route exact path="/edit/employees/:id" component={NewEmployee} />
              <Route exact path="/edit/locations/:id" component={NewLocation} />
              <Route exact path="/edit/clients/:id" component={NewClient} />
            </Route>
            <Route exact path="/employees/:id" component={EmployeeDetails} />
            <Route exact path="/locations/:id" component={LocationDetails} />
            <Route
              exact
              path="/finances/clients/:id"
              component={ClientDetails}
            />
            <Route exact path="/finances/clients" component={Clients} />
            <Route path="/">
              <Switch>
                <Redirect exact path="/" to="/home" />
                <AppLayout>
                  <Switch>
                    <Route exact path="/employees" component={Employees} />
                    <Route exact path="/locations" component={Locations} />
                    <Route exact path="/finances" component={Finances} />
                    <Route path="/home/:dateId" component={Home} />
                    <Route path="/home" component={Home} />
                  </Switch>
                </AppLayout>
              </Switch>
            </Route>
          </Switch>
        </ConstrainedRoute>
      </Switch>
    </Router>
  );
};
