import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Field, FieldProps, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { DatabaseTransaction, LocalTransaction } from "types/entities";

interface Props {
  active: boolean;
  editingTransaction: DatabaseTransaction | null;
  onClose: () => void;
  onSubmit: (value: LocalTransaction) => void;
}

const DatePickerField = ({ field, form, ...other }: FieldProps) => {
  const currentError = form.errors[field.name];

  return (
    <DatePicker
      clearable
      name={field.name}
      value={field.value}
      format="DD/MM/YYYY"
      helperText={currentError}
      error={Boolean(currentError)}
      onChange={date => form.setFieldValue(field.name, date, false)}
      {...other}
    />
  );
};

export const EmployeePayment = ({
  active,
  editingTransaction,
  onSubmit,
  onClose
}: Props) => {
  return (
    <Dialog open={active} onClose={onClose}>
      <Formik
        initialValues={
          editingTransaction
            ? {
                value: editingTransaction.value,
                type: editingTransaction.type,
                notes: editingTransaction.notes,
                date: moment(editingTransaction.created.toDate())
              }
            : {
                value: 0,
                type: "cash",
                notes: "",
                date: moment()
              }
        }
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <DialogTitle id="form-dialog-title">{`${
              editingTransaction ? "Modifica" : "Aggiungi"
            } pagamento`}</DialogTitle>
            <DialogContent>
              <Field
                name="value"
                label="Quantità"
                type="number"
                variant="outlined"
                margin="normal"
                as={TextField}
                fullWidth
              />
              <Field
                name="type"
                label="Tipo"
                type="text"
                variant="outlined"
                margin="normal"
                as={TextField}
                select
                fullWidth
              >
                <MenuItem value="cash">Contanti</MenuItem>
                <MenuItem value="check">Assegno</MenuItem>
                <MenuItem value="bank">Bonifico</MenuItem>
                <MenuItem value="credit">Carta di credito</MenuItem>
              </Field>
              <Field
                name="notes"
                label="Note"
                type="text"
                variant="outlined"
                margin="normal"
                as={TextField}
                fullWidth
              />
              <Field
                name="date"
                label="Data"
                inputVariant="outlined"
                margin="normal"
                component={DatePickerField}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Annulla
              </Button>
              <Button type="submit" color="primary">
                {`Salva ${editingTransaction ? "modifiche" : "pagamento"}`}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
