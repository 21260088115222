import { Button, CircularProgress, Typography } from "@material-ui/core";
import { BottomSheet } from "components/BottomSheet";
import { ContentfulBackdrop } from "components/ContentfulBackdrop";
import { useSelector } from "helpers/redux/useSelector";
import { SimpleIconWrapper } from "helpers/styles/Icons";
import React from "react";
import { isLoaded, useFirebase } from "react-redux-firebase";
import SimpleIcons from "simple-icons-react-component";

export const Auth = () => {
  const firebase = useFirebase();
  const auth = useSelector(({ firebase: fb }) => fb.auth);

  function loginWithGoogle() {
    return firebase.login({ provider: "google", type: "popup" });
  }

  return (
    <ContentfulBackdrop>
      <BottomSheet>
        <hgroup>
          <Typography variant="h2">Welcome!</Typography>
          <Typography variant="h6">
            Let&apos;s get you in! Create your account!
          </Typography>
        </hgroup>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={loginWithGoogle}
          disabled={!isLoaded(auth)}
        >
          {!isLoaded(auth) ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <SimpleIconWrapper inButton>
                <SimpleIcons name="Google" />
              </SimpleIconWrapper>
              Continue with Google
            </>
          )}
        </Button>
      </BottomSheet>
    </ContentfulBackdrop>
  );
};
