import { useStrings } from "data/strings";
import { LocalClient } from "types/entities";

export enum fieldKey {
  firstName = "firstName",
  lastName = "lastName",
  company = "company",
  role = "role",
  mainPhone = "mainPhone",
  mainEmail = "mainEmail",
  address = "address"
}

const { new: newClientStrings } = useStrings("clients", "it");

const formSchema = {
  initialValues: {
    firstName: "",
    lastName: "",
    mainEmail: "",
    address: ""
  },
  fields: [
    {
      id: "firstName",
      label: newClientStrings.firstName,
      type: "text",
      required: true
    },
    {
      id: "lastName",
      label: newClientStrings.lastName,
      type: "text",
      required: true
    },
    {
      id: "company",
      label: newClientStrings.company,
      type: "text",
      required: false
    },
    {
      id: "role",
      label: newClientStrings.role,
      type: "text",
      halved: false,
      required: true
    },
    {
      id: "mainEmail",
      label: newClientStrings.mainEmail,
      type: "email",
      required: true
    },
    {
      id: "mainPhone",
      label: newClientStrings.mainPhone,
      type: "tel",
      required: true
    },
    {
      id: "address",
      label: newClientStrings.address,
      type: "address",
      required: true
    }
  ]
} as {
  initialValues: LocalClient;
  fields: {
    id: fieldKey;
    label: string;
    type: "text" | "number" | "email" | "tel" | "select" | "avatar" | "address";
    required: boolean;
    halved?: boolean;
    options?: {
      value: string;
      label: string;
    }[];
  }[];
};

export default formSchema;
