import React from "react";
import ContentLoader from "react-content-loader";

const Placeholder = () => {
  return (
    <ContentLoader
      height={500}
      width={375}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="124" y="32" rx="46" ry="46" width="128" height="128" />
      <rect x="50" y="176" rx="10" ry="10" width="275" height="32" />
      <rect x="100" y="222" rx="8" ry="8" width="175" height="26" />
    </ContentLoader>
  );
};

export default Placeholder;
