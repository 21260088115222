import React from "react";
import ContentLoader from "react-content-loader";

const Placeholder = () => {
  return (
    <ContentLoader
      height={500}
      width={375}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="140" y="0" rx="24" ry="24" width="96" height="96" />
      <rect x="50" y="112" rx="8" ry="8" width="275" height="24" />
      <rect x="100" y="150" rx="8" ry="8" width="175" height="18" />
    </ContentLoader>
  );
};

export default Placeholder;
