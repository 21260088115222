import {
  Avatar,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SentIcon from '@material-ui/icons/CallMadeRounded';
import ReceivedIcon from '@material-ui/icons/CallReceivedRounded';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MoneyIcon from '@material-ui/icons/Money';
import NoteIcon from '@material-ui/icons/Note';
import { useSelector } from 'helpers/redux/useSelector';
import getCurrencySymbol from 'helpers/strings/getCurrencySymbol';
import moment from 'moment';
import React from 'react';
import { EmployeePresence } from 'redux/types';
import { DatabaseTransaction, TransactionType } from 'types/entities';

import { Activity, TransactionsRecap, TransactionsTotals } from '../styles';

function getMethodIcon(method: TransactionType) {
  // eslint-disable-next-line default-case
  switch (method) {
    case 'bank':
      return <AccountBalanceIcon />;
    case 'card':
      return <CreditCardIcon />;
    case 'cash':
      return <MoneyIcon />;
    case 'check':
      return <NoteIcon />;
  }
}

function displayMoneyAmount(amount: number, currency?: string) {
  return `${amount.toFixed(2)}${getCurrencySymbol(currency || '')}`;
}

interface Props {
  presences: EmployeePresence[];
  transactions: DatabaseTransaction[];
  onTransactionUpdate: (transactionId: string) => void;
}

export const EmployeeTransactions = ({
  presences,
  transactions,
  onTransactionUpdate
}: Props) => {
  const profile = useSelector((state) => state.firebase.profile);

  const total = presences.reduce(
    (subtotal, presence) => subtotal + presence.rate * presence.rate_amount,
    0
  );

  const transactionTotal = transactions.reduce(
    (subtotal, transaction) => subtotal + transaction.value,
    0
  );

  return (
    <Activity>
      <TransactionsRecap>
        <Typography>
          {transactionTotal - total > 0 ? 'Ti deve' : 'Gli devi'}
        </Typography>
        <Typography variant='h2'>
          {Math.abs(transactionTotal - total).toFixed(2)}
          {getCurrencySymbol(profile.app?.currency || '')}
        </Typography>
        <TransactionsTotals>
          <span>
            <SentIcon />
            <Typography>
              {displayMoneyAmount(transactionTotal, profile.app?.currency)}
            </Typography>
          </span>
          <span>
            <ReceivedIcon />
            <Typography>
              {displayMoneyAmount(total, profile.app?.currency)}
            </Typography>
          </span>
        </TransactionsTotals>
      </TransactionsRecap>
      <List disablePadding>
        {transactions.map(({ id, type, value, notes, created }) => (
          <ListItem
            key={id}
            button
            disableGutters
            onClick={() => onTransactionUpdate(id)}
          >
            <ListItemAvatar>
              <Avatar>{getMethodIcon(type || 'cash')}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${value.toFixed(2)}${getCurrencySymbol(
                profile.app?.currency || ''
              )}${notes ? ` • ${notes}` : ''}`}
              secondary={`${moment(created.toDate()).format('DD/MM/YYYY')}`}
            />
          </ListItem>
        ))}
      </List>
    </Activity>
  );
};
