import { alignCenter } from "helpers/styles/flex";
import { transparentize } from "polished";
import styled from "styled-components";

export const Contacts = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  & .MuiDivider-root {
    height: 25%;
  }

  & .MuiIconButton-root {
    height: 48px;
    width: 48px;
    margin: 0 ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const Activity = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(10)}px;

  & .MuiList-root {
    & .MuiListSubheader-root.MuiListSubheader-sticky {
      background-color: ${({ theme }) => theme.palette.background.default};
    }
  }
`;

export const ActivityDate = styled.div`
  strong {
    font-size: 2rem;
    line-height: 2rem;
  }

  display: flex;
  flex-direction: column;
  width: 40px;
  text-align: center;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

export const Nothing = styled.div`
  ${alignCenter};
  padding: ${({ theme }) => theme.spacing(4)}px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) =>
    transparentize(0.8, theme.palette.primary.main)};

  *:only-child {
    margin: 0;
  }
`;

export const TransactionsRecap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  small {
    font-size: 1rem;
  }
`;

export const TransactionsTotals = styled.div`
  & > span {
    display: inline-flex;
    width: 50%;
    box-sizing: border-box;
    flex-grow: 1;
    padding: 0 ${({ theme }) => theme.spacing(1)}px;

    svg {
      fill: ${({ theme }) => theme.palette.success.main};
    }

    .MuiTypography-root {
      margin: 0 ${({ theme }) => theme.spacing(1)}px;
    }

    &:first-child {
      flex-direction: row-reverse;

      svg {
        fill: ${({ theme }) => theme.palette.error.main};
      }
    }
  }
`;
