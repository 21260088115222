import { TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import { FieldProps } from "formik";
import { useSelector } from "helpers/redux/useSelector";
import React, { ChangeEvent } from "react";
import { useFirestore } from "react-redux-firebase";
import { capitalize } from "voca";

import { DBClient } from "../../types/entities";

// TODO: this will break on edit
// TODO: seek merging with locationField
const ClientField = ({
  clients,
  field: { name, value },
  form: { setFieldValue, setTouched },
  ...other
}: FieldProps<firebase.firestore.DocumentReference> & {
  clients: DBClient[];
}) => {
  const firestore = useFirestore();
  const auth = useSelector(state => state.firebase.auth);
  const filterOptions = createFilterOptions<DBClient>({
    matchFrom: "any",
    stringify: option =>
      `${option.lastName}${option.firstName}${option.company}`
  });

  const handleChange = (event: ChangeEvent<{}>, client: DBClient | null) =>
    setFieldValue(
      name,
      client
        ? firestore
            .collection("companyData")
            .doc(auth.uid)
            .collection("clients")
            .doc(client?.id)
        : null
    );

  return (
    <Autocomplete<DBClient>
      size="small"
      filterOptions={filterOptions}
      onChange={handleChange}
      onBlur={() => setTouched({ [name]: true })}
      options={clients}
      getOptionLabel={option =>
        `${capitalize(option.lastName)} ${capitalize(option.firstName)}`
      }
      renderInput={params => (
        <TextField
          {...params}
          {...other}
          fullWidth
          variant="filled"
          size="small"
        />
      )}
    />
  );
};

export default ClientField;
