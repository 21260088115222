export default {
  en: {
    title: "Employees",
    detail: {
      history: "Event history",
      placeholder: "No past work"
    },
    new: {
      title: "Employee data",
      photo: "Photo",
      name: "Name",
      surname: "Surname",
      specialization: "Specialization",
      rateType: "Rate type",
      rate: "Rate",
      email: "Email address",
      phone: "Phone number",
      submit: "Submit",
      errors: {
        noName: "No name provided.",
        noSurname: "No surname provided.",
        noRate: "No rate provided."
      },
      notifications: {
        create: {
          success: "was successfully added to your employees list!",
          error: "There was an error while creating the employee. Try again."
        },
        update: {
          success: "was successfully updated!",
          error: "There was an error while updating the employee. Try again."
        }
      }
    }
  },
  it: {
    title: "Dipendenti",
    detail: {
      history: "Storico",
      placeholder: "Nessun lavoro da mostrare"
    },
    new: {
      title: "Dati dipendente",
      photo: "Foto",
      name: "Nome",
      surname: "Cognome",
      specialization: "Specializzazione",
      rateType: "Cadenza",
      rate: "Retribuzione",
      email: "Indirizzo Email",
      phone: "Telefono",
      submit: "Conferma",
      errors: {
        noName: "Nome mancante.",
        noSurname: "Cognome mancante.",
        noRate: "Rate mancante."
      },
      notifications: {
        create: {
          success: "è stato aggiunto con successo",
          error:
            "Si è verificato un errore nella creazione del dipendente. Riprova."
        },
        update: {
          success: "è stato modificato con successo!",
          error:
            "Si è verificato un errore nella modifica del dipendente. Riprova."
        }
      }
    }
  }
};
