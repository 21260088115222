import styled from "styled-components";

export const Editor = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const EditorHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 0 0 24px 24px;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
  box-shadow: 0 0px 32px -20px rgba(0, 0, 0, 0.2);
`;

export const ItemsCategory = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const EndCard = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: ${({ theme }) => theme.spacing(2)}px;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0 4px 32px -4px rgba(0, 0, 0, 0.4);

  .MuiTypography-h5 {
    opacity: 1;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }

  .MuiButton-root {
    margin-top: ${({ theme }) => theme.spacing(4)}px;
  }
`;

export const EndCardTable = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(3)}px;
  grid-row-gap: ${({ theme }) => theme.spacing(0.5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  .MuiTypography {
    &-root {
      &:nth-child(even) {
        text-align: right;
      }
      &:nth-child(odd) {
        opacity: 0.6;
      }
    }
  }
`;

export const EndCardTotal = styled.div`
  .MuiTypography-h2 {
    text-align: right;

    small {
      font-size: 1rem;
      font-weight: 400;
      margin-right: 8px;
      opacity: 0.6;
    }
  }
`;
