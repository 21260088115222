import MomentUtils from "@date-io/moment";
import {
  CssBaseline,
  StylesProvider,
  ThemeProvider as MuiThemeProvider
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { firebase, rrfconfig } from "config/firebase";
import { getTheme } from "config/materialTheme";
import * as moment from "moment";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import store from "redux/store";
import { ThemeProvider } from "styled-components";

import "moment/locale/it";

import { Routes } from "./routes";
import * as serviceWorker from "./serviceWorker";

moment.locale("it");

const rrfProps = {
  firebase,
  config: rrfconfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

export const App = () => {
  const activeTheme = getTheme("light");

  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MuiThemeProvider theme={activeTheme}>
            <ThemeProvider theme={activeTheme}>
              <StylesProvider injectFirst>
                <SnackbarProvider>
                  <CssBaseline />
                  <Routes />
                </SnackbarProvider>
              </StylesProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.register();
