import { SquircleAvatar } from "helpers/styles/avatars";
import { alignCenter } from "helpers/styles/flex";
import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};

  .MuiAppBar-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
    box-shadow: none;
  }

  h5 {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const Header = styled.div`
  ${alignCenter};
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.background.paper};
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  border-radius: 0 0 ${({ theme }) => theme.spacing(4)}px 0;

  ${SquircleAvatar} {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }

  & .MuiTabs-root {
    align-self: flex-start;
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`;
