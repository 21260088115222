import { PaletteType } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  createMuiTheme,
  responsiveFontSizes,
  TypographyStyle
} from "@material-ui/core/styles";
import createPalette from "@material-ui/core/styles/createPalette";
import createTypography from "@material-ui/core/styles/createTypography";

const defaultTheme = createMuiTheme();

const titleStyle = {
  fontFamily: "Poppins",
  fontWeight: 600,
  marginBottom: ".5rem"
};

const titleTypes = ["h1", "h2", "h3", "h4", "h5", "h6"] as [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6"
];

const titlesStyles = titleTypes.reduce(
  (styles, key) => ({
    ...styles,
    [key]: {
      ...defaultTheme.typography[key],
      ...titleStyle,
      opacity: key === "h5" || key === "h6" ? 0.6 : 1
    }
  }),
  {}
) as Record<"h1" | "h2" | "h3" | "h4" | "h5" | "h6", TypographyStyle>;

const palette = createPalette({
  primary: {
    main: grey[900]
  },
  secondary: {
    main: grey[100]
  }
});

const typography = createTypography(palette, {
  fontFamily: ["Work Sans", "Roboto", "Helvetica Neue"].join(","),
  body1: {
    fontWeight: 500
  },
  body2: {
    fontWeight: 500
  },
  button: {
    fontFamily: "Poppins",
    fontWeight: 600,
    textTransform: "capitalize"
  },
  ...titlesStyles
});

const themeBase = {
  palette,
  typography: {
    ...typography,
    h2: {
      ...typography.h2,
      fontWeight: 700
    }
  },
  shape: {
    borderRadius: 16
  },
  overrides: {
    MuiButton: {
      root: {
        height: 48
      },
      sizeLarge: {
        height: 56,
        borderRadius: 20
      },
      contained: {
        boxShadow: "none"
      }
    },
    MuiAutocomplete: {
      root: {
        padding: 0
      },
      inputRoot: {
        paddingLeft: 20,
        '&[class*="MuiFilledInput-root"]': {
          padding: 0,
          paddingLeft: 16,
          paddingRight: 32
        }
      }
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
      },
      input: {
        paddingLeft: 20,
        borderRadius: "inherit"
      }
    },
    MuiInputLabel: {
      filled: {
        paddingLeft: 10
      }
    }
  },
  props: {
    MuiFilledInput: {
      disableUnderline: true
    }
  }
};

export const lightTheme = responsiveFontSizes(createMuiTheme(themeBase));

export const darkTheme = responsiveFontSizes(
  createMuiTheme({
    ...themeBase,
    palette: {
      ...themeBase.palette,
      type: "dark"
    }
  })
);

export const getTheme = (type: PaletteType) =>
  type === "light" ? lightTheme : darkTheme;
