import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  ClickAwayListener,
  Container,
  Grid,
  Grow,
  Hidden,
  Slider,
  Typography
} from '@material-ui/core';
import PlusIcon from '@material-ui/icons/AddRounded';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import MoneyIcon from '@material-ui/icons/MonetizationOnOutlined';
import EmployeesIcon from '@material-ui/icons/PeopleAltOutlined';
import LocationIcon from '@material-ui/icons/PlaceOutlined';
import { useStrings } from 'data/strings';
import { useSelector } from 'helpers/redux/useSelector';
import { SquircleAvatar } from 'helpers/styles/avatars';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { EmployeePresence, EmployeeWithId, LocationWithId } from 'redux/types';
import { capitalize } from 'voca';

import {
  BottomAppBar,
  BottomAppBarCarousel,
  CarouselItem,
  PillFab,
  PillFabTransition
} from './styles';

const BottomNav = () => {
  const auth = useSelector((state) => state.firebase.auth);
  const bottomNavStrings = useStrings('bottomBar', 'it');
  useFirestoreConnect([
    {
      collection: 'companyData',
      doc: auth.uid,
      storeAs: 'locations',
      subcollections: [
        {
          collection: 'locations',
          where: [['isArchived', '==', false]]
        }
      ]
    },
    {
      collection: 'companyData',
      doc: auth.uid,
      storeAs: 'employees',
      subcollections: [
        {
          collection: 'employees',
          where: [['isArchived', '==', false]]
        }
      ]
    }
  ]);

  const [settingPresence, setSettingPresence] = useState(false);
  const [
    presenceEmployee,
    setPresenceEmployee
  ] = useState<EmployeeWithId | null>(null);
  const [
    presenceLocation,
    setPresenceLocation
  ] = useState<LocationWithId | null>(null);
  const [presenceAmount, setPresenceAmount] = useState(1);
  const employees: EmployeeWithId[] = useSelector((state) =>
    state.firestore.ordered.employees?.filter(({ isArchived }) => !isArchived)
  );
  const locations: LocationWithId[] = useSelector((state) =>
    state.firestore.ordered.locations?.filter(({ isArchived }) => !isArchived)
  );
  const focusedDatePresences: EmployeePresence[] = useSelector(
    (state) => state.firestore.data.focusedDayPresences?.presences ?? []
  );
  const focusedDayId = useSelector(
    (state) => state.firestore.ordered.focusedDayPresences?.[0]?.id
  );

  const { enqueueSnackbar } = useSnackbar();

  const routerLocation = useLocation();
  const history = useHistory();
  const firestore = useFirestore();
  const currentPath =
    routerLocation.pathname.lastIndexOf('/') > 0
      ? routerLocation.pathname.slice(
          0,
          routerLocation.pathname.lastIndexOf('/')
        )
      : routerLocation.pathname;

  const handleEmployeePick = async (requestedEmployee: EmployeeWithId) => {
    const res = await firestore
      .collection('companyData')
      .doc(auth.uid)
      .collection('employees')
      .doc(requestedEmployee.id)
      .collection('absences')
      .where('from', '<=', new Date())
      .get();

    const isAbsent = res.docs.some((absence) => {
      return moment(absence.data().to.toDate()).isSameOrAfter(
        moment(focusedDayId, 'YYYYMMDD')
      );
    });

    if (isAbsent)
      return enqueueSnackbar(
        'Questo dipendente è al momento in ferie. Prova con un altro'
      );

    setPresenceEmployee(requestedEmployee);
  };

  const bottomTabs = [
    {
      icon: <HomeIcon />,
      label: bottomNavStrings.tabs.home.label,
      target: '/home',
      fabLabel: bottomNavStrings.tabs.home.fabLabel,
      fabCallback: () => setSettingPresence(true)
    },
    {
      icon: <EmployeesIcon />,
      label: bottomNavStrings.tabs.employees.label,
      target: '/employees',
      fabLabel: bottomNavStrings.tabs.employees.fabLabel,
      fabTarget: '/new/employees'
    },
    {
      icon: <LocationIcon />,
      label: bottomNavStrings.tabs.locations.label,
      target: '/locations',
      fabLabel: bottomNavStrings.tabs.locations.fabLabel,
      fabTarget: '/new/locations'
    },
    {
      icon: <MoneyIcon />,
      label: bottomNavStrings.tabs.finances.label,
      target: '/finances'
    }
  ];
  const targets = bottomTabs.map((tab) => tab.target);
  const currentTab = targets.indexOf(currentPath);

  const closePresenceBox = () => {
    setSettingPresence(false);
    setPresenceEmployee(null);
    setPresenceLocation(null);
    setPresenceAmount(1);
  };

  const getExtraHeight = () => {
    if (presenceLocation) return 448;
    if (presenceEmployee) return 352;
    if (settingPresence) return 136;
    return 0;
  };
  async function handleSubmitPresence() {
    try {
      await firestore
        .collection('companyData')
        .doc(auth.uid)
        .collection('presences')
        .doc(focusedDayId)
        .update({
          presences: [
            ...focusedDatePresences,
            {
              employeeId: presenceEmployee?.id,
              employeeRef: `/companyData/${auth.uid}/employees/${presenceEmployee?.id}`,
              placeId: presenceLocation?.id,
              placeRef: `/companyData/${auth.uid}/locations/${presenceLocation?.id}`,
              rate: presenceEmployee?.rate,
              // eslint-disable-next-line @typescript-eslint/camelcase
              rate_type: presenceEmployee?.rate_type,
              // eslint-disable-next-line @typescript-eslint/camelcase
              rate_amount: presenceAmount
            }
          ]
        });

      enqueueSnackbar(
        `${capitalize(
          presenceEmployee?.name
        )}'s presence was successfully added!`
      );

      closePresenceBox();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <BottomAppBar
      styled={{
        grow: Boolean(bottomTabs[currentTab].fabLabel),
        extraHeight: getExtraHeight()
      }}
    >
      <ClickAwayListener onClickAway={closePresenceBox}>
        <div>
          {bottomTabs[currentTab].fabLabel && (
            <Grow
              in={Boolean(bottomTabs[currentTab].fabLabel) && !settingPresence}
              timeout={200}
            >
              <PillFabTransition>
                <PillFab
                  onClick={
                    bottomTabs[currentTab].fabCallback
                      ? bottomTabs[currentTab].fabCallback
                      : () =>
                          history.push(bottomTabs[currentTab].fabTarget || '')
                  }
                >
                  <Box marginRight={1}>
                    <PlusIcon style={{ display: 'block' }} />
                  </Box>
                  {bottomTabs[currentTab].fabLabel}
                </PillFab>
              </PillFabTransition>
            </Grow>
          )}
          {settingPresence && (
            <>
              <Container>
                <Box marginTop={2}>
                  <Typography variant='h6'>
                    {bottomNavStrings.presenceFlow.employee}
                  </Typography>
                </Box>
              </Container>
              <BottomAppBarCarousel>
                {employees
                  ?.filter((employee) => {
                    if (
                      !focusedDatePresences.find(
                        (presence) => presence.employeeId === employee.id
                      )
                    )
                      return true;
                    if (employee.rate_type === 'hour') return true;
                    return false;
                  })
                  .map((employee) => (
                    <CarouselItem
                      active={
                        Boolean(presenceEmployee) &&
                        employee.id === presenceEmployee?.id
                      }
                      onClick={() => handleEmployeePick(employee)}
                    >
                      <SquircleAvatar src={employee.photo} size={64} alt=''>
                        {employee.photo || capitalize(employee.name)[0]}
                      </SquircleAvatar>
                      <Typography align='center'>
                        <strong>
                          <small>{capitalize(employee.name)}</small>
                        </strong>
                      </Typography>
                    </CarouselItem>
                  ))}
              </BottomAppBarCarousel>
            </>
          )}
          {presenceEmployee && (
            <>
              <Container>
                <Typography variant='h6'>
                  {bottomNavStrings.presenceFlow.location}
                </Typography>
              </Container>
              <BottomAppBarCarousel>
                {locations?.map((location) => (
                  <CarouselItem
                    size='large'
                    active={
                      Boolean(presenceLocation) &&
                      location.id === presenceLocation?.id
                    }
                    onClick={() => setPresenceLocation(location)}
                  >
                    <SquircleAvatar
                      src={location.photo || location.staticMaps.smallHero}
                      size={128}
                      alt=''
                    />
                    <Typography align='center'>
                      <strong>
                        <small>{capitalize(location.name)}</small>
                      </strong>
                    </Typography>
                  </CarouselItem>
                ))}
              </BottomAppBarCarousel>
            </>
          )}
          {presenceLocation && (
            <Container>
              {presenceEmployee?.rate_type === 'hour' && (
                <Typography>
                  {bottomNavStrings.presenceFlow.rateAmount}
                </Typography>
              )}
              <Grid container alignItems='center' spacing={4}>
                {presenceEmployee?.rate_type === 'hour' && (
                  <Grid item xs md={3}>
                    <Slider
                      min={1}
                      max={24}
                      step={1}
                      valueLabelDisplay='auto'
                      onChange={(event, value) =>
                        typeof value === 'number' && setPresenceAmount(value)
                      }
                    />
                  </Grid>
                )}
                <Hidden smDown>
                  <Grid item md />
                </Hidden>
                <Grid item xs md={3}>
                  <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={handleSubmitPresence}
                  >
                    {bottomNavStrings.presenceFlow.confirm}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          )}
        </div>
      </ClickAwayListener>
      <BottomNavigation value={targets.indexOf(currentPath)}>
        {bottomTabs.map((tab) => (
          <BottomNavigationAction
            key={tab.target}
            icon={tab.icon}
            label={tab.label}
            component={Link}
            to={tab.target}
            replace
          />
        ))}
      </BottomNavigation>
    </BottomAppBar>
  );
};

export default BottomNav;
