import { PresenceWithId, RateType } from 'redux/types';

function getRateConversion(rate: RateType) {
  if (rate === 'hour') return 1;
  return 8;
}

function getTotalPresenceHours(presences: PresenceWithId[]) {
  return presences.reduce(
    (subTotal, dayPresence) =>
      subTotal +
      dayPresence.presences.reduce(
        (dayTotal, employeePresence) =>
          dayTotal +
          getRateConversion(employeePresence.rate_type) *
            employeePresence.rate_amount,
        0
      ),
    0
  );
}

export default getTotalPresenceHours;
