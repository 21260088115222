import { Container } from "@material-ui/core";
import { darken } from "polished";
import styled, { css } from "styled-components";

export const fullScreenSlide = css`
  height: 100vh;
  width: 100vw;
`;

export const fullScreenOverlay = css`
  position: fixed;
  top: 0;
  left: 0;
`;

export const ListView = styled(Container)`
  & .MuiList-root {
    margin: 0 -16px;
  }
`;

export const PageHeader = styled.div`
  padding-top: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const InsetCard = styled.div`
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  border-radius: ${({ theme }) => theme.spacing(3)}px;
  background-color: ${({ theme }) =>
    darken(0.1, theme.palette.background.paper)};
`;
