import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import {
  AddRounded as AddIcon,
  MoreVert as MoreIcon
} from '@material-ui/icons';
import { firestore } from 'config/firebase';
import { useSelector } from 'helpers/redux/useSelector';
import { FixedFab } from 'helpers/styles/utilites';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Expense, LocalExpense } from 'types/entities';

import ExpenseDialog from './ExpenseDialog';

const LocationExpenses = ({
  expenses
}: {
  expenses: Record<string, Expense>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const match = useRouteMatch<{
    id: string;
  }>();
  const auth = useSelector((state) => state.firebase.auth);

  const [openExpenseDialog, setOpenExpenseDialog] = useState(false);
  const [moreMenuAnchor, setMoreMenuAnchor] = useState<
    HTMLButtonElement | undefined
  >();
  const [activeExpense, setActiveExpense] = useState<
    LocalExpense | undefined
  >();

  function handleCloseDialog() {
    setOpenExpenseDialog(false);
    setActiveExpense(undefined);
  }

  async function handleExpenseDeletion() {
    console.log(activeExpense);

    if (activeExpense)
      return firestore
        .collection('companyData')
        .doc(auth.uid)
        .collection('locations')
        .doc(match.params.id)
        .collection('expenses')
        .doc(activeExpense.id)
        .delete();
    return null;
  }

  async function handleDialogSubmit({ date, ...value }: LocalExpense) {
    if (!value.amount || value.amount < 0) return;
    if (!value.provider) return;
    if (!value.invoiceId) return;

    try {
      const baseRef = firestore
        .collection('companyData')
        .doc(auth.uid)
        .collection('locations')
        .doc(match.params.id)
        .collection('expenses');
      const expensePayload = {
        ...value,
        date: moment(date).toDate()
      };

      if (activeExpense) {
        await baseRef.doc(activeExpense.id).set(expensePayload);
        enqueueSnackbar('Spesa modificata con successo.');
      } else {
        await baseRef.add(expensePayload);
        enqueueSnackbar('Spesa creata con successo.');
      }
    } catch (e) {
      enqueueSnackbar(
        `Errore nella ${
          activeExpense ? 'modifica' : 'creazione'
        } della presenza. Riprova.`
      );
      console.error(e);
    }
    setOpenExpenseDialog(false);
    setActiveExpense(undefined);
  }

  console.log(expenses);

  return (
    <Grid item xs={12} sm={6} md={12}>
      <List disablePadding>
        {Object.entries(expenses || {}).map(([id, expense]) => {
          if (expense)
            return (
              <ListItem key={id} disableGutters>
                <ListItemText
                  primary={`${expense.description} @ ${expense.provider}`}
                  secondary={`${expense.invoiceId} • ${moment(
                    expense.date.toDate()
                  ).format('DD/MM/YYYY')}`}
                />
                <Typography>
                  <strong>{`${expense.amount?.toFixed(2)}€`}</strong>
                </Typography>
                <ListItemSecondaryAction>
                  <IconButton
                    edge='end'
                    onClick={(e) => {
                      setActiveExpense({
                        id,
                        ...expense,
                        date: moment(expense.date.toDate())
                      });
                      setMoreMenuAnchor(e.currentTarget);
                    }}
                  >
                    <MoreIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
        })}
      </List>

      <FixedFab color='primary' onClick={() => setOpenExpenseDialog(true)}>
        <AddIcon />
      </FixedFab>
      <Menu
        open={Boolean(moreMenuAnchor)}
        anchorEl={moreMenuAnchor}
        onClose={() => {
          setActiveExpense(undefined);
          setMoreMenuAnchor(undefined);
        }}
      >
        {activeExpense?.invoice && (
          <MenuItem
            onClick={() => {
              window.open(activeExpense?.invoice, '_blank');
              setMoreMenuAnchor(undefined);
            }}
          >
            Show invoice
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setOpenExpenseDialog(true);
            setMoreMenuAnchor(undefined);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMoreMenuAnchor(undefined);
            handleExpenseDeletion();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      <ExpenseDialog
        open={openExpenseDialog}
        expense={activeExpense}
        onClose={handleCloseDialog}
        onSubmit={handleDialogSubmit}
      />
    </Grid>
  );
};

export default LocationExpenses;
