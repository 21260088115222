import {
  Container,
  Divider,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@material-ui/core";
import InvoiceIcon from "@material-ui/icons/DescriptionOutlined";
import IdeaIcon from "@material-ui/icons/EmojiObjectsOutlined";
import PersonIcon from "@material-ui/icons/PersonOutlineOutlined";
import { ListView, PageHeader } from "helpers/styles/layout";
import React from "react";
import { useHistory } from "react-router-dom";

import { SubRoute, SubRouteAvatar, SubRoutes } from "./styles";

// TODO: export these out of here into strings
const subroutes = [
  {
    label: "Clienti",
    icon: <PersonIcon />,
    color: "#4834d4",
    route: "/finances/clients"
  },
  {
    label: "Preventivi",
    icon: <IdeaIcon />,
    color: "#f6b93b",
    route: "/finances/quotes"
  },
  {
    label: "Fatture",
    icon: <InvoiceIcon />,
    color: "#78e08f",
    route: "/finances/invoices"
  }
];

export const Finances = () => {
  const history = useHistory();

  return (
    <>
      <Container>
        <PageHeader>
          <Typography variant="h2">Finanze</Typography>
        </PageHeader>
      </Container>
      <SubRoutes>
        {subroutes.map(({ label, icon, color, route }) => (
          <SubRoute key={route} onClick={() => history.push(route)}>
            <ListItemAvatar>
              <SubRouteAvatar color={color}>{icon}</SubRouteAvatar>
            </ListItemAvatar>
            <ListItemText primary={label} />
          </SubRoute>
        ))}
      </SubRoutes>
      <Divider />
    </>
  );
};
