import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { DatePicker } from "@material-ui/pickers";
import ClientField from "components/ClientField";
import DatePickerField from "components/DatePickerField";
import LocationField from "components/LocationField";
import QuoteRecordField, {
  initialItemValues,
  initialTaskValues
} from "components/QuoteRecordField";
import { Field, FieldArray, Form, Formik } from "formik";
import { useSelector } from "helpers/redux/useSelector";
import { PageHeader } from "helpers/styles/layout";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
  DBClient,
  DBLocation,
  LocalQuote,
  QuoteItemTemplate,
  Tax
} from "types/entities";

import {
  Editor,
  EditorHeader,
  EndCard,
  EndCardTable,
  EndCardTotal,
  ItemsCategory
} from "./styles";

const fieldCommons = {
  fullWidth: true,
  variant: "filled" as const,
  size: "small" as const
};

export const QuoteEditor = () => {
  const auth = useSelector(state => state.firebase.auth);
  const firestore = useFirestore();
  const { enqueueSnackbar } = useSnackbar();
  const match = useRouteMatch<{
    id: string;
  }>();
  const location = useLocation();
  const history = useHistory();

  useFirestoreConnect([
    {
      collection: "companyData",
      doc: auth.uid,
      subcollections: [{ collection: "values", doc: "quoteItems" }],
      storeAs: "quoteItems"
    },
    {
      collection: "companyData",
      doc: auth.uid,
      subcollections: [{ collection: "values", doc: "taxes" }],
      storeAs: "taxes"
    },
    {
      collection: "companyData",
      doc: auth.uid,
      subcollections: [{ collection: "clients" }],
      storeAs: "clients"
    },
    {
      collection: "companyData",
      doc: auth.uid,
      subcollections: [{ collection: "locations" }],
      storeAs: "locations"
    }
  ]);

  const clients = useSelector(
    state => state.firestore.ordered.clients || []
  ) as DBClient[];
  const locations = useSelector(
    state => state.firestore.ordered.locations || []
  ) as DBLocation[];
  const quoteItemTemplates = useSelector(
    state => state.firestore.data.quoteItems?.templates || []
  ) as QuoteItemTemplate[];
  const taxes = useSelector(
    state => state.firestore.data.taxes?.values || []
  ) as Tax[];
  const defaultTax = useSelector(
    state =>
      state.firestore.data.taxes?.values[state.firestore.data.taxes?.default]
  ) as Tax;

  async function submitQuote(value: LocalQuote) {
    try {
      // TODO: find a better way to do this
      const validKeys = [
        "id",
        "date",
        "updates",
        "name",
        "client",
        "location",
        "items",
        "paymentTerms",
        "notes",
        "footNotes",
        "discountType",
        "discount",
        "subTotal",
        "totalDiscount",
        "totalTax",
        "total",
        "documentHistory"
      ];

      const definedValues = Object.fromEntries(
        Object.entries(value).filter(
          ([key, val]) => val !== undefined && validKeys.indexOf(key) >= 0
        )
      ) as LocalQuote;

      await firestore
        .collection("companyData")
        .doc(auth.uid)
        .collection("quotes")
        .add({
          ...definedValues,
          date: definedValues.date.toDate(),
          updates: [
            definedValues.date.toDate(),
            ...(definedValues.updates || [])
          ]
        });

      enqueueSnackbar("Preventivo creato con successo!");
      history.goBack();
    } catch (e) {
      enqueueSnackbar("Errore nella creazione del preventivo");
      console.error(e);
    }
  }

  const handleSubmit = async (values: LocalQuote) => {
    if (values.client === null || values.location === null) return;
    await submitQuote(values);
  };

  return (
    <Editor>
      <Formik<LocalQuote>
        initialValues={{
          id: "",
          date: moment(),
          updates: [],
          name: "",
          location: null,
          client: null,
          items: [],
          discountType: "percentage",
          discount: 0,
          subTotal: 0,
          totalDiscount: 0,
          totalTax: 0,
          total: 0,
          documentHistory: []
        }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
          const subTotal = values.items.reduce(
            (calculatingTotal, { cost, quantity }) => {
              return calculatingTotal + cost * quantity;
            },
            0
          );

          const netDiscount = values.items.reduce(
            (
              calculatingDiscount,
              { cost, discount, discountType, quantity }
            ) => {
              if (discountType === "percentage")
                return calculatingDiscount + (cost / 100) * discount * quantity;
              return calculatingDiscount + discount * quantity;
            },
            0
          );

          const totalTax = values.items.reduce((calculatingTax, record) => {
            const {
              taxes: appliedTaxes,
              cost,
              discountType,
              discount,
              quantity
            } = record;
            const recordUnitDiscount =
              discountType === "percentage"
                ? (cost / 100) * discount
                : discount;
            const recordUnitDiscounted = cost - recordUnitDiscount;
            const unitTax = (recordUnitDiscounted / 100) * appliedTaxes[0];
            return calculatingTax + unitTax * quantity;
          }, 0);

          const globalDiscount =
            values.discountType === "percentage"
              ? ((subTotal - netDiscount + totalTax) / 100) * values.discount
              : values.discount;

          const total = subTotal - netDiscount + totalTax - globalDiscount;

          return (
            <Form
              onSubmit={e => {
                e.preventDefault();
                setFieldValue("subTotal", subTotal);
                setFieldValue("totalDiscount", netDiscount + globalDiscount);
                setFieldValue("totalTax", totalTax);
                setFieldValue("total", total);
                handleSubmit();
              }}
            >
              <EditorHeader>
                <Container>
                  <PageHeader>
                    <Box display="flex">
                      <Typography variant="h2">Preventivo</Typography>
                      <Box marginRight={2} />
                      <Field
                        fullWidth
                        name="id"
                        as={TextField}
                        variant="filled"
                        label="Numero"
                        size="small"
                      />
                    </Box>
                  </PageHeader>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="name"
                        as={TextField}
                        variant="filled"
                        label="Nome preventivo"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Field
                        fullWidth
                        name="date"
                        component={DatePickerField}
                        label="Data"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <Field
                        fullWidth
                        name="client"
                        component={ClientField}
                        label="Cliente"
                        size="small"
                        clients={clients}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="location"
                        component={LocationField}
                        label="Luogo"
                        size="small"
                        locations={locations}
                      />
                    </Grid>
                  </Grid>
                </Container>
              </EditorHeader>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FieldArray
                      name="items"
                      render={arrayHelpers => (
                        <>
                          {values.items
                            ?.map((item, index) => (
                              <Field
                                key={index}
                                name={`items.${index}`}
                                component={QuoteRecordField}
                                templates={quoteItemTemplates}
                                taxes={taxes}
                                onDelete={() => arrayHelpers.remove(index)}
                              />
                            ))
                            .reduce(
                              (itemsTuple, itemElement, index) => {
                                const targetTupleItem =
                                  values.items[index].type === "item" ? 0 : 1;
                                const itemsArray = [...itemsTuple[0]].concat(
                                  targetTupleItem === 0 ? [itemElement] : []
                                );
                                const taskArray = [...itemsTuple[1]].concat(
                                  targetTupleItem === 1 ? [itemElement] : []
                                );
                                return [itemsArray, taskArray];
                              },
                              [[] as JSX.Element[], [] as JSX.Element[]]
                            )
                            .map((itemElementsTupleItem, index) => (
                              <ItemsCategory
                                key={index === 0 ? "Materiale" : "Attività"}
                              >
                                <Typography variant="h6">
                                  {index === 0 ? "Materiale" : "Attività"}
                                </Typography>
                                {itemElementsTupleItem}
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    arrayHelpers.push(
                                      index === 0
                                        ? {
                                            ...initialItemValues,
                                            taxes: [defaultTax.percentage]
                                          }
                                        : {
                                            ...initialTaskValues,
                                            taxes: [defaultTax.percentage]
                                          }
                                    )
                                  }
                                >
                                  {`Aggiungi ${
                                    index === 0 ? "Materiale" : "Attività"
                                  }`}
                                </Button>
                              </ItemsCategory>
                            ))}
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Sconto lordo</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      {...fieldCommons}
                      as={TextField}
                      name="discountType"
                      label="Tipo sconto"
                      select
                    >
                      <MenuItem value="percentage">percentuale</MenuItem>
                      <MenuItem value="fixed">fisso</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      {...fieldCommons}
                      as={TextField}
                      name="discount"
                      label="Sconto"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Note</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      {...fieldCommons}
                      as={TextField}
                      multiline
                      rows={5}
                      name="notes"
                      label="Note"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      {...fieldCommons}
                      as={TextField}
                      multiline
                      rows={5}
                      name="paymentTerms"
                      label="Termini di pagamento"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      {...fieldCommons}
                      as={TextField}
                      multiline
                      rows={5}
                      name="footNotes"
                      label="Note di coda"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EndCard>
                      <Typography variant="h5">Riepilogo</Typography>
                      <EndCardTable>
                        <Typography>Subtotale</Typography>
                        <Typography>
                          <strong>{subTotal.toFixed(2)}€</strong>
                        </Typography>
                        <Typography>Sconto netto</Typography>
                        <Typography>
                          <strong>-{netDiscount.toFixed(2)}€</strong>
                        </Typography>
                        <Typography>Totale tasse</Typography>
                        <Typography>
                          <strong>{totalTax.toFixed(2)}€</strong>
                        </Typography>
                        <Typography>Sconto lordo</Typography>
                        <Typography>
                          <strong>-{globalDiscount.toFixed(2)}€</strong>
                        </Typography>
                      </EndCardTable>
                      <EndCardTotal>
                        <Typography variant="h2">
                          <small>Totale:</small>
                          {total.toFixed(2)}€
                        </Typography>
                      </EndCardTotal>
                      <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        type="submit"
                      >
                        {isSubmitting ? (
                          <CircularProgress color="primary" size={24} />
                        ) : (
                          "Salva preventivo"
                        )}
                      </Button>
                    </EndCard>
                  </Grid>
                </Grid>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </Editor>
  );
};
