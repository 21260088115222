import { Box, CircularProgress, Typography } from '@material-ui/core';
import { storage } from 'config/firebase';
import { InsetCard } from 'helpers/styles/layout';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  value: string;
  fileRef: string;
  onChange: (newUrl: string) => void;
  disabled?: boolean;
}

export const FileField = ({ value, fileRef, disabled, onChange }: Props) => {
  const [isUploading, setIsUploading] = useState(false);

  const handleDrop = useCallback(
    async ([uploadedFile]: File[]) => {
      setIsUploading(true);
      const newPicRef = await storage
        .ref(
          `${fileRef}${uploadedFile.name.slice(
            uploadedFile.name.lastIndexOf('.')
          )}`
        )
        .put(uploadedFile);
      const fileUrl = await newPicRef.ref.getDownloadURL();

      onChange(fileUrl);
      setIsUploading(false);
    },
    [fileRef]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    disabled
  });

  console.log(value);

  return (
    <InsetCard>
      <Box display='flex' alignItems='center' {...getRootProps()}>
        <input {...getInputProps()} />
        <Box marginLeft={2}>
          {isUploading && <CircularProgress />}
          <Typography>
            {value
              ? 'File ricevuto. Procedi pure'
              : 'Drag and drop a file here or tap to select it.'}
          </Typography>
        </Box>
      </Box>
    </InsetCard>
  );
};
