import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  TextField
} from '@material-ui/core';
import DatePickerField from 'components/DatePickerField';
import { FileField } from 'components/FileField';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useSelector } from 'helpers/redux/useSelector';
import moment from 'moment';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LocalExpense } from 'types/entities';

interface Props extends Omit<Omit<DialogProps, 'onClose'>, 'onSubmit'> {
  expense?: LocalExpense;
  onSubmit: (value: LocalExpense) => void;
  onClose: () => void;
}

const ExpenseDialog: React.FC<Props> = ({
  expense,
  onSubmit,
  onClose,
  ...rest
}: Props) => {
  const auth = useSelector((state) => state.firebase.auth);
  const match = useRouteMatch<{
    id: string;
  }>();

  return (
    <Dialog onClose={onClose} {...rest}>
      <Formik<LocalExpense>
        initialValues={
          expense || {
            date: moment(),
            invoiceId: '',
            provider: ''
          }
        }
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue
        }) => (
          <Form>
            <DialogTitle id='form-dialog-title'>{`${
              expense ? 'Modifica' : 'Aggiungi'
            } spesa`}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name='date'
                    label='Data'
                    inputVariant='outlined'
                    margin='normal'
                    component={DatePickerField}
                    fullWidth
                  />
                  <Field
                    name='invoiceId'
                    label='Numero fattura'
                    variant='outlined'
                    margin='normal'
                    as={TextField}
                    fullWidth
                  />
                  <Field
                    name='amount'
                    label='Importo'
                    type='number'
                    variant='outlined'
                    margin='normal'
                    as={TextField}
                    fullWidth
                  />
                  <Field
                    name='provider'
                    label='Fornitore'
                    variant='outlined'
                    margin='normal'
                    as={TextField}
                    fullWidth
                  />
                  <Field
                    name='description'
                    label='Descrizione'
                    variant='outlined'
                    margin='normal'
                    as={TextField}
                    fullWidth
                  />
                  {values.invoiceId && values.date && (
                    <Field name='invoice' id='invoice'>
                      {({ field: fieldProps }: FieldProps) => {
                        return (
                          <FileField
                            {...fieldProps}
                            onChange={
                              (value) => setFieldValue('invoice', value) // TODO: this is ugly
                            }
                            fileRef={`clients/${auth.uid}/invoices/${
                              match.params.id
                            }/${values.invoiceId}_${moment(
                              values.date
                            ).unix()}`}
                          />
                        );
                      }}
                    </Field>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Annulla</Button>
              <Button variant='contained' color='primary' type='submit'>
                {`Salva ${expense ? 'modifiche' : 'spesa'}`}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ExpenseDialog;
