import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBackRounded";
import EditIcon from "@material-ui/icons/EditOutlined";
import MailIcon from "@material-ui/icons/MailOutlineRounded";
import PhoneIcon from "@material-ui/icons/PhoneOutlined";
import { Header, Wrapper } from "components/Profile";
import { useSelector } from "helpers/redux/useSelector";
import { SquircleAvatar } from "helpers/styles/avatars";
import React, { useState } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DBClient } from "types/entities";
import { capitalize } from "voca";

import Placeholder from "./Placeholder";
import { Contacts } from "./styles";

export const ClientDetails = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const match = useRouteMatch<{
    id: string;
  }>();
  const auth = useSelector(state => state.firebase.auth);

  useFirestoreConnect([
    {
      collection: "companyData",
      doc: auth.uid,
      subcollections: [{ collection: "clients" }],
      orderBy: [["lastName", "asc"]],
      storeAs: "clients"
    }
  ]);

  const client: DBClient = useSelector(
    state => state.firestore.data.clients?.[match.params.id]
  );

  return (
    <Wrapper>
      <AppBar elevation={1} position="static">
        <Toolbar>
          <IconButton edge="start" onClick={() => history.goBack()}>
            <BackIcon />
          </IconButton>
          <Box flexGrow={1} />
          <IconButton
            edge="end"
            onClick={() => history.push(`/edit/clients/${match.params.id}`)}
          >
            <EditIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {client ? (
        <>
          <Header>
            <SquircleAvatar size={96}>
              {capitalize(client.firstName)[0]}
            </SquircleAvatar>
            <Typography variant="h4">{`${capitalize(
              client.firstName
            )} ${capitalize(client.lastName)}`}</Typography>
            <Typography variant="h6">
              {client.role ? `${client.role} @ ` : ""}
              {client.company}
            </Typography>
            <Contacts>
              {client.mainPhone && (
                <>
                  <IconButton
                    color="primary"
                    component="a"
                    href={`tel:${client.mainPhone}`}
                  >
                    <PhoneIcon />
                  </IconButton>
                  <Divider orientation="vertical" />
                </>
              )}
              {client.mainPhone && (
                <IconButton
                  color="primary"
                  component="a"
                  href={`mailto:${client.mainEmail}`}
                >
                  <MailIcon />
                </IconButton>
              )}
            </Contacts>
            <Tabs
              value={activeTab}
              onChange={(e, value) => setActiveTab(value)}
              indicatorColor="primary"
            >
              <Tab label="Cantieri" />
              <Tab label="Preventivi" />
              <Tab label="Fatture" />
            </Tabs>
          </Header>
        </>
      ) : (
        <Placeholder />
      )}
    </Wrapper>
  );
};
