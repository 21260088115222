export default {
  en: {
    greeting: "Hi",
    placeholders: {
      locations: {
        title: "Nobody's here",
        body: "Seems like everyone's been on holiday today. Or have they?"
      }
    }
  },
  it: {
    greeting: "Ciao",
    placeholders: {
      locations: {
        title: "Nessuno qui",
        body: "Sembra che siano stati tutti in ferie oggi. Ma ne siamo sicuri?"
      }
    }
  }
};
