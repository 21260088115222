import { Box, CircularProgress, Typography } from "@material-ui/core";
import { storage } from "config/firebase";
import { SquircleAvatar } from "helpers/styles/avatars";
import { InsetCard } from "helpers/styles/layout";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

interface Props {
  value: string;
  fileRef: string;
  onChange: (newUrl: string) => void;
  disabled: boolean;
}

export const AvatarField = ({ value, fileRef, disabled, onChange }: Props) => {
  const [newPicPreview, setNewPicPreview] = useState<null | string>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleDrop = useCallback(
    async ([uploadedPic]: File[]) => {
      setIsUploading(true);
      setNewPicPreview(URL.createObjectURL(uploadedPic));
      const newPicRef = await storage
        .ref(
          `${fileRef}${uploadedPic.name.slice(
            uploadedPic.name.lastIndexOf(".")
          )}`
        )
        .put(uploadedPic);
      const fileUrl = await newPicRef.ref.getDownloadURL();

      onChange(fileUrl);
      setIsUploading(false);
    },
    [fileRef]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: "image/*",
    disabled
  });

  return (
    <InsetCard>
      <Box display="flex" alignItems="center" {...getRootProps()}>
        <input {...getInputProps()} />
        <SquircleAvatar size={64} src={isUploading ? "" : value}>
          {isUploading && <CircularProgress />}
        </SquircleAvatar>
        <Box marginLeft={2}>
          <Typography>
            Drag and drop a photo here or tap to select it.
          </Typography>
        </Box>
      </Box>
    </InsetCard>
  );
};
