import { Box, Grid, Typography } from '@material-ui/core';
import PlaceIcon from '@material-ui/icons/PlaceOutlined';
import getTotalPresenceHours from 'helpers/calculations/getTotalPresenceHours';
import { kFormatter } from 'helpers/strings/kFormatter';
import { SquircleAvatar } from 'helpers/styles/avatars';
import React from 'react';
import { Location, PresenceWithId } from 'redux/types';
import { Expense } from 'types/entities';
import { capitalize } from 'voca';

import { AddressBadge } from '../styles';

const LocationDashboard = ({
  location,
  locationPresenceDates,
  expenses
}: {
  location: Location;
  locationPresenceDates: PresenceWithId[];
  expenses: Record<string, Expense>;
}) => {
  return (
    <>
      <Grid item xs={12} sm={6} md={12}>
        <AddressBadge>
          <SquircleAvatar size={56}>
            <PlaceIcon />
          </SquircleAvatar>
          <Typography>
            {capitalize(location.address.formatted_address)}
          </Typography>
        </AddressBadge>
      </Grid>
      <Grid item xs={12} sm={6} md={12}>
        <Typography variant='h5'>Statistiche luogo</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box
              bgcolor='grey.200'
              padding={2}
              textAlign='center'
              borderRadius={16}
            >
              <Typography variant='h4'>
                {kFormatter(
                  locationPresenceDates.reduce(
                    (subTotal, dayPresence) =>
                      subTotal +
                      dayPresence.presences.reduce(
                        (dayTotal, employeePresence) =>
                          dayTotal +
                          employeePresence.rate * employeePresence.rate_amount,
                        0
                      ),
                    0
                  ) +
                    (expenses
                      ? Object.values(expenses).reduce(
                          (subTotal, expense) =>
                            subTotal + (expense.amount || 0),
                          0
                        )
                      : 0)
                )}
              </Typography>
              <Typography>Spesa totale</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              bgcolor='grey.200'
              padding={2}
              textAlign='center'
              borderRadius={16}
            >
              <Typography variant='h4'>
                {kFormatter(
                  locationPresenceDates.reduce(
                    (subTotal, dayPresence) =>
                      subTotal +
                      dayPresence.presences.reduce(
                        (dayTotal, employeePresence) =>
                          dayTotal +
                          employeePresence.rate * employeePresence.rate_amount,
                        0
                      ),
                    0
                  )
                )}
                <small>€</small>
              </Typography>
              <Typography>Manodopera</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              bgcolor='grey.200'
              padding={2}
              textAlign='center'
              borderRadius={16}
            >
              <Typography variant='h4'>
                {kFormatter(
                  expenses
                    ? Object.values(expenses).reduce(
                        (subTotal, expense) => subTotal + (expense.amount || 0),
                        0
                      )
                    : 0
                )}
                <small>€</small>
              </Typography>
              <Typography>Materiali</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              bgcolor='grey.200'
              padding={2}
              textAlign='center'
              borderRadius={16}
            >
              <Typography variant='h4'>
                {getTotalPresenceHours(locationPresenceDates)}
                <small>€</small>
              </Typography>
              <Typography>Ore di lavoro</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LocationDashboard;
