import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBackOutlined";
import { AvatarField } from "components/AvatarField";
import { BottomSheet } from "components/BottomSheet";
import { ContentfulBackdrop } from "components/ContentfulBackdrop";
import { useStrings } from "data/strings";
import { Field, FieldProps, Form, Formik, FormikErrors } from "formik";
import { useSelector } from "helpers/redux/useSelector";
import { useSnackbar } from "notistack";
import React from "react";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { capitalize } from "voca";

import formSchema, { EmployeeValues } from "./formSchema";

const { new: newEmployeeStrings } = useStrings("employees", "it");

function validateEmployee(values: EmployeeValues) {
  const errors = {} as FormikErrors<EmployeeValues>;

  if (values.name === "") errors.name = newEmployeeStrings.errors.noName;
  if (values.surname === "")
    errors.surname = newEmployeeStrings.errors.noSurname;
  if (values.rate && values.rate < 1)
    errors.rate = newEmployeeStrings.errors.noRate;

  return errors;
}

const NewEmployee = () => {
  const auth = useSelector(state => state.firebase.auth);
  const firestore = useFirestore();
  const { enqueueSnackbar } = useSnackbar();
  const match = useRouteMatch<{
    id: string;
  }>();
  const location = useLocation();
  const history = useHistory();

  useFirestoreConnect([
    {
      collection: "companyData",
      doc: auth.uid,
      subcollections: [{ collection: "employees" }],
      storeAs: "employees",
      orderBy: [["name", "asc"]]
    }
  ]);

  const employee = useSelector(
    state => state.firestore.data.employees?.[match.params.id]
  );

  async function submitEmployee(value: EmployeeValues) {
    try {
      await firestore
        .collection("companyData")
        .doc(auth.uid)
        .collection("employees")
        .add({
          ...value,
          name: value.name.toLowerCase(),
          surname: value.surname.toLowerCase(),
          specialization: value.specialization
            ? value.specialization.toLowerCase()
            : null,
          created: new Date(),
          lastUpdate: new Date()
        });

      enqueueSnackbar(
        `${capitalize(value.name)} ${
          newEmployeeStrings.notifications.create.success
        }`
      );
      history.goBack();
    } catch (e) {
      enqueueSnackbar(newEmployeeStrings.notifications.create.error);
      console.error(e);
    }
  }

  async function updateEmployee(value: EmployeeValues) {
    try {
      await firestore
        .collection("companyData")
        .doc(auth.uid)
        .collection("employees")
        .doc(match.params.id)
        .update({
          ...value,
          name: value.name.toLowerCase(),
          surname: value.surname.toLowerCase(),
          lastUpdate: new Date()
        });

      enqueueSnackbar(
        `${capitalize(value.name)} ${
          newEmployeeStrings.notifications.update.success
        }`
      );
      history.goBack();
    } catch (e) {
      enqueueSnackbar(newEmployeeStrings.notifications.update.error);
      console.error(e);
    }
  }

  return (
    <ContentfulBackdrop>
      <AppBar elevation={0}>
        <Toolbar>
          <IconButton edge="start" onClick={() => history.goBack()}>
            <BackIcon color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Formik<EmployeeValues>
        initialValues={(employee || formSchema.initialValues) as EmployeeValues}
        validate={validateEmployee}
        onSubmit={
          location.pathname.indexOf("new") >= 0
            ? submitEmployee
            : updateEmployee
        }
        enableReinitialize
      >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue
        }) => {
          const canSubmit =
            location.pathname.indexOf("new") >= 0
              ? touched.name &&
                touched.surname &&
                touched.rate_type &&
                touched.rate
              : true;

          console.log(values);

          return (
            <BottomSheet textAlign="left" scrollable noHeader>
              <Container maxWidth="md">
                <Typography variant="h5">{newEmployeeStrings.title}</Typography>
                <Form>
                  <Grid container spacing={2} justify="center">
                    {formSchema.fields.map(field => {
                      const renderField = () => {
                        switch (field.type) {
                          case "avatar":
                            return (
                              <Field id={field.id} name={field.id}>
                                {({ field: fieldProps, form }: FieldProps) => {
                                  return (
                                    <AvatarField
                                      {...fieldProps}
                                      onChange={
                                        value => setFieldValue(field.id, value) // TODO: this is ugly
                                      }
                                      fileRef={`employees/${auth.uid}/${form.values.surname}_${form.values.name}`}
                                      disabled={
                                        !form.values.name ||
                                        !form.values.surname
                                      }
                                    />
                                  );
                                }}
                              </Field>
                            );
                          case "select":
                            return (
                              <TextField
                                id={field.id}
                                name={field.id}
                                label={field.label}
                                value={values[field.id]}
                                type={field.type}
                                error={Boolean(errors[field.id])}
                                helperText={errors[field.id]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="filled"
                                select
                                fullWidth
                              >
                                {field.options?.map(({ value, label }) => (
                                  <MenuItem key={value} value={value}>
                                    {label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            );
                          default:
                            return (
                              <TextField
                                id={field.id}
                                name={field.id}
                                label={field.label}
                                value={values[field.id]}
                                type={field.type}
                                error={Boolean(errors[field.id])}
                                helperText={errors[field.id]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="filled"
                                InputLabelProps={{
                                  shrink: Boolean(values[field.id])
                                }}
                                fullWidth
                              />
                            );
                        }
                      };

                      return (
                        <Grid
                          key={field.id}
                          item
                          xs={field.halved ? 6 : 12}
                          sm={field.halved ? 3 : 6}
                          md={field.halved ? 6 : 12}
                        >
                          {renderField()}
                        </Grid>
                      );
                    })}
                    <Grid item xs={12} sm={6} md={12}>
                      <Box marginTop={2}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={!canSubmit}
                        >
                          {isSubmitting ? (
                            <CircularProgress color="secondary" size={24} />
                          ) : (
                            newEmployeeStrings.submit
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </Container>
            </BottomSheet>
          );
        }}
      </Formik>
    </ContentfulBackdrop>
  );
};

export default NewEmployee;
