import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField
} from "@material-ui/core";
import { ChipMenuItem } from "components/ChipMenuItem/styles";
import GooglePlaceField from "components/GooglePlaceField";
import currencies from "data/currencies.json";
import { Field, Form, Formik, FormikErrors } from "formik";
import React from "react";

import { formFields } from "./fields";

interface FormProps<T> {
  onSubmit: (values: T) => void;
}

export interface ProfileValues {
  name: string;
  surname: string;
  email: string;
  phone: string | null;
}

function getSelectOptions(id: string) {
  if (id === "currency")
    return Object.values(currencies).map(currency => ({
      symbol: currency.symbol,
      nativeSymbol: currency.symbol_native,
      code: currency.code,
      name: currency.name,
      plural: currency.name_plural,
      decimalDigits: currency.decimal_digits
    }));
  return [];
}

function validateProfile(values: ProfileValues) {
  const errors = {} as FormikErrors<ProfileValues>;

  if (values.name === "") errors.name = "No name provided.";
  if (values.surname === "") errors.surname = "No surname provided.";
  if (values.email === "") errors.email = "No email provided.";
  //   else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
  //     errors.email = "Invalid email address.";

  return errors;
}

export const ProfileForm = ({ onSubmit }: FormProps<ProfileValues>) => {
  return (
    <Formik
      initialValues={{
        name: "",
        surname: "",
        email: "",
        phone: null
      }}
      validate={validateProfile}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          onSubmit(values);
          actions.setSubmitting(false);
        }, 100);
      }}
    >
      {({ handleChange, handleBlur, errors, touched, isSubmitting }) => {
        const canSubmit = touched.name && touched.surname && touched.email;

        return (
          <Form>
            <Container maxWidth="md">
              <Grid container spacing={2} justify="center">
                {formFields.profile.map(field => (
                  <Grid key={field.id} item xs={12} sm={6} md={12}>
                    <TextField
                      id={field.id}
                      name={field.id}
                      label={field.label}
                      error={Boolean(errors[field.id])}
                      helperText={errors[field.id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                ))}
                <Grid item xs={12} sm={6} md={12}>
                  <Box marginTop={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!canSubmit}
                    >
                      {isSubmitting ? (
                        <CircularProgress color="secondary" size={24} />
                      ) : (
                        "Continue"
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export interface CompanyValues {
  name: string;
  address: string;
  phone: string;
  email: string;
  companyId:
    | {
        country: string;
        type: string;
        value: string | Record<string, string>[];
      }
    | string;
  fax: string | null;
  website: string | null;
  logo: string | null;
}

function validateCompany(values: CompanyValues) {
  const errors = {} as FormikErrors<CompanyValues>;

  if (values.name === "") errors.name = "No name provided.";
  if (values.address === "") errors.address = "No address provided.";
  if (values.phone === "") errors.phone = "No phone provided.";
  if (values.email === "") errors.email = "No email provided.";
  if (values.companyId === "") errors.companyId = "No compnay Id provided.";
  //   else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
  //     errors.email = "Invalid email address.";

  return errors;
}

export const CompanyForm = ({ onSubmit }: FormProps<CompanyValues>) => {
  return (
    <Formik
      initialValues={{
        name: "",
        address: "",
        phone: "",
        email: "",
        companyId: "",
        fax: null,
        website: null,
        logo: null
      }}
      validate={validateCompany}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          onSubmit(values);
          actions.setSubmitting(false);
        }, 100);
      }}
    >
      {({
        handleChange,
        handleBlur,
        errors,
        values,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched
      }) => {
        const canSubmit =
          touched.name &&
          touched.address &&
          touched.phone &&
          touched.email &&
          touched.companyId;

        return (
          <Form>
            <Container maxWidth="md">
              <Grid container spacing={2} justify="center">
                {formFields.company.map(field => (
                  <Grid key={field.id} item xs={12} sm={6} md={12}>
                    {field.type === "address" ? (
                      <Field name={field.id}>
                        {() => (
                          <GooglePlaceField
                            id={field.id}
                            name={field.id}
                            label={field.label}
                            value={values[field.id]}
                            // TODO: Better definition
                            onChange={(value: any) => {
                              setFieldValue(field.id, {
                                ...value,
                                geometry: {
                                  lat: value.geometry.location.lat(),
                                  lon: value.geometry.location.lng()
                                }
                              });
                            }}
                            onTouch={() => setFieldTouched(field.id)}
                            variant="filled"
                            error={Boolean(errors[field.id])}
                            helperText={errors[field.id]}
                            autoComplete="new-password"
                            fullWidth
                          />
                        )}
                      </Field>
                    ) : (
                      <TextField
                        id={field.id}
                        name={field.id}
                        label={field.label}
                        type={field.type}
                        error={Boolean(errors[field.id])}
                        helperText={errors[field.id]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="filled"
                        fullWidth
                      />
                    )}
                  </Grid>
                ))}
                <Grid item xs={12} sm={6} md={12}>
                  <Box marginTop={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!canSubmit}
                    >
                      {isSubmitting ? (
                        <CircularProgress color="secondary" size={24} />
                      ) : (
                        "Continue"
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export interface AppValues {
  currency: string | null;
}

function validateApp(values: AppValues) {
  const errors = {} as FormikErrors<AppValues>;

  if (!values.currency) errors.currency = "No currency provided.";
  //   else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
  //     errors.email = "Invalid email address.";

  return errors;
}

export const AppForm = ({ onSubmit }: FormProps<AppValues>) => {
  return (
    <Formik
      initialValues={{
        currency: null
      }}
      validate={validateApp}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          onSubmit(values);
          actions.setSubmitting(false);
        }, 100);
      }}
    >
      {({ handleChange, handleBlur, errors, touched, isSubmitting }) => {
        const canSubmit = Boolean(touched.currency);

        return (
          <Form>
            <Container maxWidth="md">
              <Grid container spacing={2} justify="center">
                {formFields.app.map(field => (
                  <Grid key={field.id} item xs={12} sm={6} md={12}>
                    {field.type === "select" ? (
                      <Field name={field.id}>
                        {() => (
                          <TextField
                            id={field.id}
                            name={field.id}
                            label={field.label}
                            error={Boolean(errors[field.id])}
                            helperText={errors[field.id]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="filled"
                            SelectProps={{
                              MenuProps: {
                                style: {
                                  maxHeight: 320
                                }
                              }
                            }}
                            select
                            fullWidth
                          >
                            {getSelectOptions(field.id).map(currency => (
                              <ChipMenuItem
                                key={currency.code}
                                value={currency.code}
                              >
                                <strong>{currency.code}</strong> •{" "}
                                {currency.name}
                              </ChipMenuItem>
                            ))}
                          </TextField>
                        )}
                      </Field>
                    ) : (
                      <TextField
                        id={field.id}
                        name={field.id}
                        label={field.label}
                        type={field.type}
                        error={Boolean(errors[field.id])}
                        helperText={errors[field.id]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="filled"
                        fullWidth
                      />
                    )}
                  </Grid>
                ))}
                <Grid item xs={12} sm={6} md={12}>
                  <Box marginTop={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!canSubmit}
                    >
                      {isSubmitting ? (
                        <CircularProgress color="secondary" size={24} />
                      ) : (
                        "Continue"
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};
