import styled from "styled-components";
import { Avatar, ListItemAvatar } from "@material-ui/core";

export const SquircleAvatar = styled(Avatar)<{
  size?: number;
}>`
  border-radius: ${props => ((props.size || 0) < 92 ? "35%" : "32px")};
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`;

export const SquircleListItemAvatar = styled(ListItemAvatar)<{
  size?: number;
}>`
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  min-width: ${props => props.size}px;
  margin-right: 12px;

  ${SquircleAvatar} {
    background-color: ${({ theme }) => theme.palette.primary.main};
    font-family: Poppins;
    font-weight: 700;
    height: 100%;
    width: 100%;
  }
`;
