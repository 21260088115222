import {
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import { useStrings } from 'data/strings';
import { useSelector } from 'helpers/redux/useSelector';
import getCurrencySymbol from 'helpers/strings/getCurrencySymbol';
import { SquircleAvatar, SquircleListItemAvatar } from 'helpers/styles/avatars';
import { ListView, PageHeader } from 'helpers/styles/layout';
import React, { useState } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { Employee, EmployeeWithId } from 'redux/types';
import { capitalize } from 'voca';

const Employees = () => {
  const employeesStrings = useStrings('employees', 'it');
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const [isArchiveMode, setIsArchiveMode] = useState(false);
  const [query, setQuery] = useState('');

  useFirestoreConnect([
    {
      collection: 'companyData',
      doc: auth.uid,
      subcollections: [{ collection: 'employees' }],
      storeAs: 'employees',
      orderBy: [['name', 'asc']]
    }
  ]);

  const employees: EmployeeWithId[] =
    useSelector((state) => {
      const employeeRecords: [string, Employee][] = Object.entries(
        state.firestore.data.employees || {}
      );
      return employeeRecords.map((record) => ({
        id: record[0],
        ...record[1]
      }));
    }) || [];

  return (
    <ListView>
      <PageHeader>
        <Typography variant='h2'>{employeesStrings.title}</Typography>
      </PageHeader>
      <Toolbar disableGutters style={{ justifyContent: 'space-between' }}>
        <TextField
          size='small'
          variant='outlined'
          placeholder='Search...'
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Switch
          color='primary'
          checked={isArchiveMode}
          onChange={() => setIsArchiveMode(!isArchiveMode)}
        />
      </Toolbar>
      <List>
        {employees
          .filter(({ isArchived, name }) => {
            return (
              (isArchived ?? false) === isArchiveMode &&
              name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
            );
          })
          .map(
            ({
              id,
              photo,
              name,
              surname,
              specialization,
              rate,
              rate_type: rateType
            }) => (
              <ListItem
                key={id}
                button
                component={Link}
                to={`/employees/${id}`}
              >
                <SquircleListItemAvatar size={56}>
                  <SquircleAvatar src={photo} alt=''>
                    {photo || capitalize(name)[0]}
                  </SquircleAvatar>
                </SquircleListItemAvatar>
                <ListItemText
                  primary={`${capitalize(name)} ${capitalize(surname)}`}
                  secondary={specialization}
                />
              </ListItem>
            )
          )}
      </List>
    </ListView>
  );
};

export default Employees;
