export default {
  en: {
    menu: {
      logout: "logout"
    }
  },
  it: {
    menu: {
      logout: "logout"
    }
  }
};
