export default {
  en: {
    rateTypes: {
      hour: "Hourly",
      day: "Daily",
      week: "Weekly",
      month: "Monthly",
      year: "Yearly"
    }
  },
  it: {
    rateTypes: {
      hour: "Oraria",
      day: "Giornaliera",
      week: "Settimanale",
      month: "Mensile",
      year: "Annuale"
    }
  }
};
