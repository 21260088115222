import React from "react";

import { Backdrop } from "./styles";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const ContentfulBackdrop: React.FC<Props> = ({ children }: Props) => {
  return <Backdrop>{children}</Backdrop>;
};
