import { fullScreenSlide } from "helpers/styles/layout";
import styled from "styled-components";

export const Screen = styled.div`
  ${fullScreenSlide};
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 96px;

  & .MuiBottomNavigation-root {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
`;

export const ScreenBody = styled.div`
  flex-grow: 1;
`;
