// TODO: translate this

export default {
  en: {
    title: "Clienti",
    detail: {
      history: "Storico",
      placeholder: "Nessun lavoro da mostrare"
    },
    new: {
      title: "Dati cliente",
      firstName: "Nome",
      lastName: "Cognome",
      company: "Azienda",
      role: "Ruolo aziendale",
      mainPhone: "Telefono principale",
      mainEmail: "Indirizzo Email principale",
      address: "Indirizzo",
      submit: "Conferma",
      errors: {
        noFirstName: "Nome mancante.",
        noLastName: "Cognome mancante.",
        noEmail: "Email mancante.",
        noAddress: "Indirizzo mancante."
      },
      notifications: {
        create: {
          success: "è stato aggiunto con successo",
          error:
            "Si è verificato un errore nella creazione del cliente. Riprova."
        },
        update: {
          success: "è stato modificato con successo!",
          error:
            "Si è verificato un errore nella modifica del cliente. Riprova."
        }
      }
    }
  },
  it: {
    title: "Clienti",
    detail: {
      history: "Storico",
      placeholder: "Nessun lavoro da mostrare"
    },
    new: {
      title: "Dati cliente",
      firstName: "Nome",
      lastName: "Cognome",
      company: "Azienda",
      role: "Ruolo aziendale",
      mainPhone: "Telefono principale",
      mainEmail: "Indirizzo Email principale",
      address: "Indirizzo",
      submit: "Conferma",
      errors: {
        noFirstName: "Nome mancante.",
        noLastName: "Cognome mancante.",
        noEmail: "Email mancante.",
        noAddress: "Indirizzo mancante."
      },
      notifications: {
        create: {
          success: "è stato aggiunto con successo",
          error:
            "Si è verificato un errore nella creazione del cliente. Riprova."
        },
        update: {
          success: "è stato modificato con successo!",
          error:
            "Si è verificato un errore nella modifica del cliente. Riprova."
        }
      }
    }
  }
};
