export interface LocationValues {
  photo: string | null;
  name: string;
  // TODO: better definition for this boy
  address: any;
}

export enum fieldKey {
  photo = "photo",
  name = "name",
  address = "address"
}

const formSchema = {
  initialValues: {
    photo: null,
    name: "",
    address: null
  },
  fields: [
    {
      id: "name",
      label: "Name",
      type: "text",
      required: true
    },
    {
      id: "address",
      label: "Address",
      type: "address",
      required: true
    }
  ]
} as {
  initialValues: LocationValues;
  fields: {
    id: fieldKey;
    label: string;
    type: "text" | "number" | "email" | "tel" | "select" | "address";
    required: boolean;
    halved?: boolean;
    options?: {
      value: string;
      label: string;
    }[];
  }[];
};

export default formSchema;
