export default {
  en: {
    tabs: {
      home: {
        label: "Home",
        fabLabel: "Add presence"
      },
      employees: {
        label: "Employees",
        fabLabel: "Add employee"
      },
      locations: {
        label: "Locations",
        fabLabel: "Add location"
      },
      finances: {
        label: "Finances"
      }
    },
    presenceFlow: {
      employee: "Employee",
      location: "Location",
      rateAmount: "Presence Amount",
      confirm: "Confirm"
    }
  },
  it: {
    tabs: {
      home: {
        label: "Home",
        fabLabel: "Aggiungi presenza"
      },
      employees: {
        label: "Dipendenti",
        fabLabel: "Aggiungi dipendente"
      },
      locations: {
        label: "Luoghi",
        fabLabel: "Aggiungi luogo"
      },
      finances: {
        label: "Finanza"
      }
    },
    presenceFlow: {
      employee: "Dipendente",
      location: "Luogo",
      rateAmount: "Quantità di lavoro",
      confirm: "Conferma"
    }
  }
};
