import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBackOutlined";
import { AvatarField } from "components/AvatarField";
import { BottomSheet } from "components/BottomSheet";
import { ContentfulBackdrop } from "components/ContentfulBackdrop";
import { useStrings } from "data/strings";
import { Field, FieldProps, Form, Formik, FormikErrors } from "formik";
import { useSelector } from "helpers/redux/useSelector";
import { useSnackbar } from "notistack";
import React from "react";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { LocalClient } from "types/entities";
import { capitalize } from "voca";

import formSchema from "./formSchema";

const { new: newClientStrings } = useStrings("clients", "it");

function validateClient(values: LocalClient) {
  const errors = {} as FormikErrors<LocalClient>;

  if (values.firstName === "")
    errors.firstName = newClientStrings.errors.noFirstName;
  if (values.lastName === "")
    errors.lastName = newClientStrings.errors.noLastName;
  if (values.company === "") errors.company = newClientStrings.errors.noCompany;
  if (values.address === "") errors.address = newClientStrings.errors.noAddress;

  return errors;
}

const NewClient = () => {
  const auth = useSelector(state => state.firebase.auth);
  const firestore = useFirestore();
  const { enqueueSnackbar } = useSnackbar();
  const match = useRouteMatch<{
    id: string;
  }>();
  const location = useLocation();
  const history = useHistory();

  useFirestoreConnect([
    {
      collection: "companyData",
      doc: auth.uid,
      subcollections: [{ collection: "clients" }],
      storeAs: "clients",
      orderBy: [["firstName", "asc"]]
    }
  ]);

  const client = useSelector(
    state => state.firestore.data.clients?.[match.params.id]
  );

  async function submitClient(value: LocalClient) {
    try {
      await firestore
        .collection("companyData")
        .doc(auth.uid)
        .collection("clients")
        .add(
          JSON.parse(
            JSON.stringify({
              ...value,
              firstName: value.firstName.toLowerCase(),
              lastName: value.lastName.toLowerCase(),
              created: new Date(),
              lastUpdate: new Date()
            })
          )
        );

      enqueueSnackbar(
        `${capitalize(value.firstName)} ${
          newClientStrings.notifications.create.success
        }`
      );
      history.goBack();
    } catch (e) {
      enqueueSnackbar(newClientStrings.notifications.create.error);
      console.error(e);
    }
  }

  async function updateClient(value: LocalClient) {
    try {
      await firestore
        .collection("companyData")
        .doc(auth.uid)
        .collection("clients")
        .doc(match.params.id)
        .update(
          JSON.parse(
            JSON.stringify({
              ...value,
              firstName: value.firstName.toLowerCase(),
              lastName: value.lastName.toLowerCase(),
              lastUpdate: new Date()
            })
          )
        );

      enqueueSnackbar(
        `${capitalize(value.firstName)} ${
          newClientStrings.notifications.update.success
        }`
      );
      history.goBack();
    } catch (e) {
      enqueueSnackbar(newClientStrings.notifications.update.error);
      console.error(e);
    }
  }

  return (
    <ContentfulBackdrop>
      <AppBar elevation={0}>
        <Toolbar>
          <IconButton edge="start" onClick={() => history.goBack()}>
            <BackIcon color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Formik<LocalClient>
        initialValues={(client || formSchema.initialValues) as LocalClient}
        validate={validateClient}
        onSubmit={
          location.pathname.indexOf("new") >= 0 ? submitClient : updateClient
        }
        enableReinitialize
      >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue
        }) => {
          const canSubmit =
            location.pathname.indexOf("new") >= 0
              ? touched.firstName &&
                touched.lastName &&
                touched.mainEmail &&
                touched.address
              : true;

          console.log(values);

          return (
            <BottomSheet textAlign="left" scrollable noHeader>
              <Container maxWidth="md">
                <Typography variant="h5">{newClientStrings.title}</Typography>
                <Form>
                  <Grid container spacing={2} justify="center">
                    {formSchema.fields.map(field => {
                      const renderField = () => {
                        switch (field.type) {
                          case "avatar":
                            return (
                              <Field id={field.id} firstName={field.id}>
                                {({ field: fieldProps, form }: FieldProps) => {
                                  return (
                                    <AvatarField
                                      {...fieldProps}
                                      onChange={
                                        value => setFieldValue(field.id, value) // TODO: this is ugly
                                      }
                                      fileRef={`clients/${auth.uid}/${form.values.lastName}_${form.values.firstName}`}
                                      disabled={
                                        !form.values.firstName ||
                                        !form.values.lastName
                                      }
                                    />
                                  );
                                }}
                              </Field>
                            );
                          case "select":
                            return (
                              <TextField
                                id={field.id}
                                name={field.id}
                                label={field.label}
                                value={values[field.id]}
                                type={field.type}
                                error={Boolean(errors[field.id])}
                                helperText={errors[field.id]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="filled"
                                select
                                fullWidth
                              >
                                {field.options?.map(({ value, label }) => (
                                  <MenuItem key={value} value={value}>
                                    {label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            );
                          default:
                            return (
                              <TextField
                                id={field.id}
                                name={field.id}
                                label={field.label}
                                value={values[field.id]}
                                type={field.type}
                                error={Boolean(errors[field.id])}
                                helperText={errors[field.id]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="filled"
                                InputLabelProps={{
                                  shrink: Boolean(values[field.id])
                                }}
                                fullWidth
                              />
                            );
                        }
                      };

                      return (
                        <Grid
                          key={field.id}
                          item
                          xs={field.halved ? 6 : 12}
                          sm={field.halved ? 3 : 6}
                          md={field.halved ? 6 : 12}
                        >
                          {renderField()}
                        </Grid>
                      );
                    })}
                    <Grid item xs={12} sm={6} md={12}>
                      <Box marginTop={2}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={!canSubmit}
                        >
                          {isSubmitting ? (
                            <CircularProgress color="secondary" size={24} />
                          ) : (
                            newClientStrings.submit
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </Container>
            </BottomSheet>
          );
        }}
      </Formik>
    </ContentfulBackdrop>
  );
};

export default NewClient;
