import { DatePicker } from '@material-ui/pickers';
import { FieldProps } from 'formik';
import { Moment } from 'moment';
import React from 'react';

const DatePickerField = ({ field, form, ...other }: FieldProps) => {
  const currentError = form.errors[field.name];

  return (
    <DatePicker
      clearable
      name={field.name}
      value={field.value}
      format='dd/MM/yyyy'
      helperText={currentError}
      error={Boolean(currentError)}
      labelFunc={(date) => date?.format('DD/MM/YYYY') ?? ''}
      inputVariant='filled'
      onError={(error) => {
        if (error !== currentError) {
          form.setFieldError(field.name, error?.toString() || '');
        }
      }}
      onChange={(date) => form.setFieldValue(field.name, date, false)}
      {...other}
    />
  );
};

export default DatePickerField;
