import {
  AppBar,
  Box,
  Chip,
  Container,
  Divider,
  Fab,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography
} from '@material-ui/core';
import PlusIcon from '@material-ui/icons/AddRounded';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import AbsenceIcon from '@material-ui/icons/BeachAccessOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import MailIcon from '@material-ui/icons/MailOutlineRounded';
import MoneyIcon from '@material-ui/icons/MonetizationOnOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';
import PresenceDialog from 'components/PresenceDialog';
import { Header, Wrapper } from 'components/Profile';
import { useStrings } from 'data/strings';
import groupArray from 'group-array';
import { useSelector } from 'helpers/redux/useSelector';
import getCurrencySymbol from 'helpers/strings/getCurrencySymbol';
import { SquircleAvatar } from 'helpers/styles/avatars';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Employee, EmployeePresence, Location, Presence } from 'redux/types';
import {
  DatabaseAbsence,
  DatabaseTransaction,
  LocalAbsence,
  LocalTransaction
} from 'types/entities';
import { capitalize } from 'voca';

import { EmployeeAbsence } from './components/EmployeeAbsence';
import { EmployeeAbsences } from './components/EmployeeAbsences';
import { EmployeeHistory } from './components/EmployeeHistory';
import { EmployeePayment } from './components/EmployeePayment';
import { EmployeeTransactions } from './components/EmployeeTransactions';
import Placeholder from './Placeholder';
import { Contacts } from './styles';

const { rateTypes } = useStrings('general', 'it');
const { detail } = useStrings('employees', 'it');

export const EmployeeDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState(0);
  const [activePaymentDialog, setActivePaymentDialog] = useState(false);
  const [activeAbsenceDialog, setActiveAbsenceDialog] = useState(false);
  const [activeEditHistoryPanel, setActiveEditHistoryPanel] = useState(false);
  const [
    activeTransaction,
    setActiveTransaction
  ] = useState<DatabaseTransaction | null>(null);
  const [activeAbsence, setActiveAbsence] = useState<DatabaseAbsence | null>(
    null
  );
  const [activeHistoryDate, setActiveHistoryDate] = useState<string | null>(
    null
  );
  const [openDial, setOpenDial] = useState(false);
  const [
    activeHistoryRecord,
    setActiveHistoryRecord
  ] = useState<EmployeePresence | null>(null);
  const firestore = useFirestore();
  const history = useHistory();
  const match = useRouteMatch<{
    id: string;
  }>();
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);

  async function handleNewPayment({ date, ...value }: LocalTransaction) {
    if (value.value < 1) return;
    try {
      const baseRef = firestore
        .collection('companyData')
        .doc(auth.uid)
        .collection('employees')
        .doc(match.params.id)
        .collection('transactions');
      const transactionPayload = {
        ...value,
        created: moment(date).toDate()
      };

      if (activeTransaction) {
        await baseRef.doc(activeTransaction.id).set(transactionPayload);
        enqueueSnackbar('Transazione modificata con successo.');
      } else {
        await baseRef.add(transactionPayload);
        enqueueSnackbar('Transazione creata con successo.');
      }
    } catch (e) {
      enqueueSnackbar(
        `Errore nella ${
          activeTransaction ? 'modifica' : 'creazione'
        } della transazione. Riprova.`
      );
      console.error(e);
    }
    setActivePaymentDialog(false);
    setActiveTransaction(null);
  }

  async function handleNewAbsence({ from, to, ...value }: LocalAbsence) {
    if (from.isAfter(to)) return;
    try {
      const baseRef = firestore
        .collection('companyData')
        .doc(auth.uid)
        .collection('employees')
        .doc(match.params.id)
        .collection('absences');
      const absencePayload = {
        ...value,
        from: moment(from).toDate(),
        to: moment(to).toDate()
      };

      if (activeAbsence) {
        await baseRef.doc(activeAbsence.id).set(absencePayload);
        enqueueSnackbar('Assenza modificata con successo.');
      } else {
        await baseRef.add(absencePayload);
        enqueueSnackbar('Assenza creata con successo.');
      }
    } catch (e) {
      enqueueSnackbar(
        `Errore nella ${
          activeTransaction ? 'modifica' : 'creazione'
        } dell'assenza'. Riprova.`
      );
      console.error(e);
    }
    setActiveAbsenceDialog(false);
    setActiveAbsence(null);
  }

  useFirestoreConnect([
    {
      collection: 'companyData',
      doc: auth.uid,
      subcollections: [{ collection: 'employees' }],
      orderBy: [['name', 'asc']],
      storeAs: 'employees'
    },
    {
      collection: 'companyData',
      doc: auth.uid,
      subcollections: [
        {
          collection: 'employees',
          doc: match.params.id,
          subcollections: [
            {
              collection: 'transactions'
            }
          ]
        }
      ],
      orderBy: [['created', 'asc']],
      storeAs: 'transactions'
    },
    {
      collection: 'companyData',
      doc: auth.uid,
      subcollections: [
        {
          collection: 'employees',
          doc: match.params.id,
          subcollections: [
            {
              collection: 'absences'
            }
          ]
        }
      ],
      orderBy: [['from', 'asc']],
      storeAs: 'absences'
    },
    {
      collection: 'companyData',
      doc: auth.uid,
      subcollections: [{ collection: 'locations' }],
      storeAs: 'locations'
    },
    {
      collection: 'companyData',
      doc: auth.uid,
      subcollections: [
        {
          collection: 'presences'
        }
      ],
      storeAs: 'presences'
    }
  ]);

  const locations: Location[] = useSelector(
    (state) => state.firestore.data.locations
  );

  const employee: Employee = useSelector(
    (state) => state.firestore.data.employees?.[match.params.id]
  );

  const presences: Record<string, Presence> = useSelector(
    (state) => state.firestore.data.presences
  );

  const transactions: DatabaseTransaction[] = useSelector(
    (state) => state.firestore.ordered.transactions
  );

  const absences: DatabaseAbsence[] = useSelector(
    (state) => state.firestore.ordered.absences
  );

  const employeePresences: {
    [date: string]: {
      month: string;
      date: Moment;
      presence: EmployeePresence;
    }[];
  } = useSelector((state) =>
    groupArray(
      state.firestore.ordered.presences
        ?.map((datePresence) => ({
          month: moment.unix(datePresence?.date.seconds).format('YYYY-MMM'),
          date: moment.unix(datePresence?.date.seconds),
          presence: datePresence.presences?.filter(
            (employeePresence: EmployeePresence) =>
              employeePresence?.employeeId === match.params.id
          )[0]
        }))
        .filter((datePresence) => datePresence?.presence),
      'month'
    )
  );

  function handleTransactionUpdate(transactionId: string) {
    const transaction = transactions.filter(
      ({ id }) => id === transactionId
    )[0];

    setActiveTransaction(transaction);
    setActivePaymentDialog(true);
  }

  function handleAbsenceUpdate(absenceId: string) {
    const absence = absences.filter(({ id }) => id === absenceId)[0];

    setActiveAbsence(absence);
    setActiveAbsenceDialog(true);
  }

  const ActiveEmployeeTab = () => {
    switch (activeTab) {
      case 0:
        return (
          <EmployeeHistory
            presences={employeePresences}
            payout={
              transactions
                ? transactions.reduce((subtotal, transaction) => {
                    return subtotal + transaction.value;
                  }, 0)
                : 0
            }
            onPresenceEdit={(date: string) => {
              const activeRecord = presences[date].presences.find(
                ({ employeeId }) => employeeId === match.params.id
              );

              if (!activeRecord) return;
              setActiveHistoryDate(date);
              setActiveHistoryRecord(activeRecord);
              setActiveEditHistoryPanel(true);
            }}
          />
        );
      case 1:
        return (
          <EmployeeTransactions
            transactions={transactions}
            presences={Object.values(employeePresences)
              .flat()
              .map(({ presence }) => presence)}
            onTransactionUpdate={handleTransactionUpdate}
          />
        );
      case 2:
        return (
          <EmployeeAbsences
            absences={absences}
            onAbsenceUpdate={handleAbsenceUpdate}
          />
        );
      default:
        return null;
    }
  };

  async function handleHistoryUpdate(value: EmployeePresence) {
    if (value.rate < 1 || !activeHistoryDate) return;
    try {
      const ref = firestore
        .collection('companyData')
        .doc(auth.uid)
        .collection('presences')
        .doc(activeHistoryDate);

      const newDayPresence = { ...presences[activeHistoryDate] };
      newDayPresence.presences[
        newDayPresence.presences.findIndex(
          ({ employeeId }) => employeeId === value.employeeId
        )
      ] = value;

      await ref.set(newDayPresence);
      enqueueSnackbar('Presenza modificata con successo.');
    } catch (e) {
      enqueueSnackbar(`Errore nella modifica della presenza. Riprova.`);
      console.error(e);
    }
    setActiveEditHistoryPanel(false);
    setActiveHistoryRecord(null);
    setActiveHistoryDate(null);
  }

  return (
    <Wrapper>
      <AppBar elevation={1} position='static'>
        <Toolbar>
          <IconButton edge='start' onClick={() => history.goBack()}>
            <BackIcon />
          </IconButton>
          <Box flexGrow={1} />
          <IconButton
            onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              if (!confirm('Vuoi veramente archiviare questo dipendente?'))
                return;

              const employeeRef = firestore
                .collection('companyData')
                .doc(auth.uid)
                .collection('employees')
                .doc(match.params.id);

              employeeRef.update({
                isArchived: !(employee.isArchived ?? false)
              });
            }}
          >
            <ArchiveIcon />
          </IconButton>
          <IconButton
            edge='end'
            onClick={() => history.push(`/edit/employees/${match.params.id}`)}
          >
            <EditIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {employee ? (
        <>
          <Header>
            <SquircleAvatar size={96} src={employee.photo} alt=''>
              {employee.photo || capitalize(employee.name)[0]}
            </SquircleAvatar>
            <Typography variant='h4'>{`${capitalize(
              employee.name
            )} ${capitalize(employee.surname)}`}</Typography>
            <Typography variant='h6'>{`${
              employee.specialization
                ? `${capitalize(employee.specialization)} • `
                : ''
            }${employee.rate}${getCurrencySymbol(
              profile.app?.currency || ''
            )}/${employee.rate_type}`}</Typography>
            {employee.isArchived && (
              <Chip color='secondary' label='Archiviato' />
            )}
            <Contacts>
              {employee.phone && (
                <>
                  <IconButton
                    color='primary'
                    component='a'
                    href={`tel:${employee.phone}`}
                  >
                    <PhoneIcon />
                  </IconButton>
                  <Divider orientation='vertical' />
                </>
              )}
              {employee.phone && (
                <IconButton
                  color='primary'
                  component='a'
                  href={`mailto:${employee.email}`}
                >
                  <MailIcon />
                </IconButton>
              )}
            </Contacts>
            <Tabs
              value={activeTab}
              onChange={(e, value) => setActiveTab(value)}
              indicatorColor='primary'
            >
              <Tab label='Storico' />
              <Tab label='Libro paga' />
              <Tab label='Assenze' />
            </Tabs>
          </Header>
          <Container>
            <ActiveEmployeeTab />
          </Container>
        </>
      ) : (
        <Placeholder />
      )}
      <Box
        position='fixed'
        bottom={16}
        right={16}
        style={{ pointerEvents: 'none' }}
      >
        <SpeedDial
          ariaLabel='Action Dial'
          icon={<PlusIcon />}
          onClose={() => setOpenDial(false)}
          onOpen={() => setOpenDial(true)}
          open={openDial}
          direction='up'
        >
          <SpeedDialAction
            tooltipOpen
            icon={<MoneyIcon />}
            tooltipTitle='Pagamento'
            onClick={() => {
              setOpenDial(false);
              setActivePaymentDialog(true);
            }}
          />
          <SpeedDialAction
            tooltipOpen
            icon={<AbsenceIcon />}
            tooltipTitle='Assenza'
            onClick={() => {
              setOpenDial(false);
              setActiveAbsenceDialog(true);
            }}
          />
        </SpeedDial>
      </Box>

      <EmployeePayment
        active={activePaymentDialog}
        editingTransaction={activeTransaction}
        onSubmit={handleNewPayment}
        onClose={() => {
          setActivePaymentDialog(false);
          setActiveTransaction(null);
        }}
      />

      <EmployeeAbsence
        active={activeAbsenceDialog}
        editingAbsence={activeAbsence}
        onSubmit={handleNewAbsence}
        onClose={() => {
          setActiveAbsenceDialog(false);
          setActiveAbsence(null);
        }}
      />

      {activeHistoryRecord && (
        <PresenceDialog
          presence={activeHistoryRecord}
          open={activeEditHistoryPanel}
          onClose={() => {
            setActiveHistoryDate(null);
            setActiveHistoryRecord(null);
            setActiveEditHistoryPanel(false);
          }}
          onSubmit={handleHistoryUpdate}
        />
      )}
    </Wrapper>
  );
};
