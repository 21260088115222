import { AppBar, ButtonBase, Fab } from "@material-ui/core";
import { SquircleAvatar } from "helpers/styles/avatars";
import { darken } from "polished";
import styled from "styled-components";

export const BottomAppBar = styled(AppBar).attrs({
  position: "fixed",
  color: "inherit"
})<{
  styled: {
    extraHeight: number;
    grow: boolean;
  };
}>`
  top: auto;
  bottom: 0;
  max-height: ${({ styled }) => (styled.grow ? 80 : 56) + styled.extraHeight}px;
  max-width: ${({ theme }) => theme.breakpoints.values.lg}px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: ${({ theme }) => theme.shape.borderRadius * 1.5}px;
  border-top-right-radius: ${({ theme }) => theme.shape.borderRadius * 1.5}px;
  transition: 0.2s ease-out;

  h6 {
    margin: 0;
  }
`;

export const BottomAppBarCarousel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 0 ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(2)}px;
  margin-left: ${({ theme }) => (theme.breakpoints.up("lg") ? 0 : -12)}px;
  overflow-x: auto;

  ${SquircleAvatar} {
    display: inline-flex;
    align-self: center;
  }
`;

export const CarouselItem = styled(ButtonBase)<{
  size?: "small" | "medium" | "large";
  active: boolean;
}>`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  flex-basis: ${props => (props.size === "large" ? 152 : 88)}px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 1.5 * 1.5}px;
  padding: ${({ theme }) => theme.spacing(1.5)}px 0
    ${({ theme }) => theme.spacing(1)}px;
  background-color: ${props =>
    props.active
      ? darken(0.1, props.theme.palette.background.paper)
      : "transparent"};

  small {
    font-size: 0.8rem;
  }
`;

export const PillFabTransition = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  z-index: 100;
`;

export const PillFab = styled(Fab).attrs({
  variant: "extended",
  color: "primary",
  size: "medium"
})`
  min-width: 176px !important;
  width: auto;
  text-decoration: none;
`;
