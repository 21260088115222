import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Typography
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useStrings } from 'data/strings';
import { useSelector } from 'helpers/redux/useSelector';
import getCurrencySymbol from 'helpers/strings/getCurrencySymbol';
import moment from 'moment';
import React from 'react';
import { EmployeePresence } from 'redux/types';
import { capitalize } from 'voca';

import { Activity, ActivityDate, Nothing } from '../styles';

interface Props {
  presences: {
    [date: string]: {
      month: string;
      date: moment.Moment;
      presence: EmployeePresence;
    }[];
  };
  payout: number;
  onPresenceEdit: (date: string) => void;
}

export const EmployeeHistory = ({
  presences,
  payout,
  onPresenceEdit
}: Props) => {
  const { detail } = useStrings('employees', 'it');

  const locations: Location[] = useSelector(
    (state) => state.firestore.data.locations
  );
  const profile = useSelector((state) => state.firebase.profile);
  let payoutToMark = payout;

  return (
    <Activity>
      {Object.keys(presences).length ? (
        <List disablePadding>
          {Object.entries(presences ?? [])
            .map(([date, presenceRecords]) => {
              const monthTotal = presenceRecords.reduce(
                (subtotal, presenceRecord) => {
                  return (
                    subtotal +
                    presenceRecord.presence.rate *
                      presenceRecord.presence.rate_amount
                  );
                },
                0
              );
              const leftToPay =
                payoutToMark > monthTotal ? 0 : monthTotal - payoutToMark;
              if (payoutToMark > monthTotal) payoutToMark -= monthTotal;
              else payoutToMark = 0;

              return (
                <>
                  <ListSubheader disableGutters>
                    <Typography variant='h6'>{`${date} • ${leftToPay}/${monthTotal}${getCurrencySymbol(
                      profile.app?.currency || ''
                    )}`}</Typography>
                  </ListSubheader>

                  {presenceRecords.reverse().map((presence: any) => {
                    return (
                      <ListItem
                        key={
                          moment
                            .unix(presence?.date.seconds)
                            .format('YYYYMMDD') + presence.presence.placeId
                        }
                        disableGutters
                        dense
                      >
                        <ActivityDate>
                          <strong>{presence.date.format('DD')}</strong>
                          {presence.date.format('ddd ')}
                        </ActivityDate>
                        <ListItemText
                          primary={capitalize(
                            (locations[presence.presence.placeId] as any).name
                          )}
                          secondary={`${getCurrencySymbol(
                            profile.app?.currency || ''
                          )}${presence.presence.rate *
                            presence.presence.rate_amount}`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge='end'
                            aria-label='edit'
                            onClick={() => {
                              onPresenceEdit(presence.date.format('YYYYMMDD'));
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </>
              );
            })
            .reverse()}
        </List>
      ) : (
        <Nothing>
          <Typography variant='h6'>{detail.placeholder}</Typography>
        </Nothing>
      )}
    </Activity>
  );
};
