import { storage } from "config/firebase";
import imageToBase64 from "image-to-base64";
import b64toBlob from "./b64toBlob";
import blobToFile from "./blobToFile";

async function storeRemotePicture(
  url: string,
  ref: string,
  filename: string,
  ext: string = "jpg"
): Promise<string> {
  const base64 = await imageToBase64(url);
  const blob = await b64toBlob(base64);
  const file = await blobToFile(blob, filename);

  const squareThumbnailStorageRef = await storage
    .ref(`${ref}/${filename}.${ext}`)
    .put(file);

  return squareThumbnailStorageRef.ref.getDownloadURL();
}

export default storeRemotePicture;
