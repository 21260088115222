import firebaseCore from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/performance";
import "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyB_DxorxKg3t-00a9G49inkZ6sof5-ZJ9E",
  authDomain: "el1flem-patron.firebaseapp.com",
  databaseURL: "https://el1flem-patron.firebaseio.com",
  projectId: "el1flem-patron",
  storageBucket: "el1flem-patron.appspot.com",
  messagingSenderId: "340117677581",
  appId: "1:340117677581:web:476a06e2d92e4fc8"
};

export const rrfconfig = {
  userProfile: "users",
  useFirestoreForProfile: true
};

if (!firebaseCore.apps.length) {
  firebaseCore.initializeApp(firebaseConfig);
}

export const firebase = firebaseCore;
export const firestore = firebaseCore.firestore();
export const storage = firebaseCore.storage();
