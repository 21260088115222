import styled from "styled-components";
import { MenuItem } from "@material-ui/core";

export const ChipMenuItem = styled(MenuItem)`
  strong {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    padding: 2px 12px;
    min-width: 64px;
    text-align: center;
    border-radius: 8px;
    margin-right: 8px;
  }
`;
