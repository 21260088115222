import { LoadingOverlay } from "components/LoadingOverlay";
import { useSelector } from "helpers/redux/useSelector";
import React from "react";
import { isEmpty, isLoaded } from "react-redux-firebase";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface Props {
  onlyVisitors?: boolean;
  needsOnboarding?: boolean;
  children: JSX.Element | JSX.Element[];
}

function ConstrainedRoute({
  onlyVisitors,
  needsOnboarding,
  children,
  ...rest
}: Props & RouteProps): JSX.Element {
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);

  if (!isLoaded(auth) || !isLoaded(profile)) return <LoadingOverlay />;
  if (isEmpty(auth) && !onlyVisitors) return <Redirect to="/auth" />;
  if (!isEmpty(auth) && onlyVisitors) return <Redirect to="/" />;
  if (needsOnboarding && !profile.onboarded)
    return <Redirect to="/onboarding" />;
  return <Route {...rest}>{children}</Route>;
}

export default ConstrainedRoute;
