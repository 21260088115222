import { TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import { FieldProps } from "formik";
import { useSelector } from "helpers/redux/useSelector";
import React, { ChangeEvent } from "react";
import { useFirestore } from "react-redux-firebase";
import { capitalize } from "voca";

import { DBLocation } from "../../types/entities";

// TODO: this will break on edit
// TODO: seek merging with clientField
const LocationField = ({
  locations,
  field: { name, value },
  form: { setFieldValue, setTouched },
  ...other
}: FieldProps<firebase.firestore.DocumentReference> & {
  locations: DBLocation[];
}) => {
  const firestore = useFirestore();
  const auth = useSelector(state => state.firebase.auth);
  const filterOptions = createFilterOptions<DBLocation>({
    matchFrom: "any",
    stringify: option => `${option.name}${option.address}`
  });

  const handleChange = (event: ChangeEvent<{}>, location: DBLocation | null) =>
    setFieldValue(
      name,
      location
        ? firestore
            .collection("companyData")
            .doc(auth.uid)
            .collection("locations")
            .doc(location?.id)
        : null
    );

  return (
    <Autocomplete<DBLocation>
      size="small"
      filterOptions={filterOptions}
      onChange={handleChange}
      onBlur={() => setTouched({ [name]: true })}
      options={locations}
      getOptionLabel={option => capitalize(option.name)}
      renderInput={params => (
        <TextField
          {...params}
          {...other}
          fullWidth
          variant="filled"
          size="small"
        />
      )}
    />
  );
};

export default LocationField;
