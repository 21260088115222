import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';
import OtherIcon from '@material-ui/icons/AppsOutlined';
import HolidayIcon from '@material-ui/icons/BeachAccessOutlined';
import HealthIcon from '@material-ui/icons/HealingOutlined';
import moment from 'moment';
import React from 'react';
import { AbsenceType, DatabaseAbsence } from 'types/entities';

import { Activity } from '../styles';

function getAbsenceIcon(type: AbsenceType) {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'health':
      return <HealthIcon />;
    case 'holidays':
      return <HolidayIcon />;
    default:
      return <OtherIcon />;
  }
}

interface Props {
  absences: DatabaseAbsence[];
  onAbsenceUpdate: (absenceId: string) => void;
}

export const EmployeeAbsences = ({ absences, onAbsenceUpdate }: Props) => {
  return (
    <Activity>
      <List disablePadding>
        {absences.map(({ id, type, from, to, reason, notes }) => (
          <ListItem
            key={id}
            button
            disableGutters
            onClick={() => onAbsenceUpdate(id)}
          >
            <ListItemAvatar>
              <Avatar>{getAbsenceIcon(type || 'other')}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`dal ${moment(from.toDate()).format(
                'DD/MM/YY'
              )} al ${moment(to.toDate()).format('DD/MM/YY')}`}
              secondary={`${reason}${notes ? ` • ${notes}` : ''}`}
            />
          </ListItem>
        ))}
      </List>
    </Activity>
  );
};
