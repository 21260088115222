import { alignCenter } from "helpers/styles/flex";
import { fullScreenSlide } from "helpers/styles/layout";
import styled from "styled-components";

export const Congratulations = styled.div`
  ${fullScreenSlide};
  ${alignCenter};
  flex-direction: column;
  color: ${({ theme }) => theme.palette.primary.contrastText};

  img {
    height: 192px;
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  }

  p {
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  }
`;
