import { Box, Grid } from "@material-ui/core";
import React from "react";

import { Sheet, SheetWrapper, Wrapper } from "./styles";

type Props = {
  noHeader?: boolean;
  scrollable?: boolean;
  textAlign?: "left" | "right" | "center";
  topSpacing?: number;
  children?: JSX.Element | (JSX.Element | null)[] | null;
};

export const BottomSheet: React.FC<Props> = ({
  noHeader,
  scrollable,
  textAlign,
  topSpacing,
  children
}: Props) => {
  return (
    <Wrapper
      noHeader={noHeader}
      scrollable={scrollable}
      topSpacing={topSpacing}
    >
      <Grid container justify="flex-end">
        <Grid item xs={12} md={6} lg={4}>
          <SheetWrapper>
            <Box marginTop="auto">
              <Sheet>
                <Box textAlign={textAlign || "center"}>{children}</Box>
              </Sheet>
            </Box>
          </SheetWrapper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};
