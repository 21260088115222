import { Fab, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const NakedLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const FixedFab = styled(Fab)`
  position: fixed;
  bottom: ${({ theme }) => theme.spacing(3)}px;
  right: ${({ theme }) => theme.spacing(3)}px;
`;

export const ColorTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  opacity: 1;
`;
