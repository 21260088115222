import React from "react";
import { Screen, ScreenBody } from "./styles";
import BottomNav from "components/BottomNav";

interface Props {
  children: JSX.Element | JSX.Element[] | string | null;
}

const AppLayout: React.FC<Props> = ({ children }: Props) => {
  return (
    <Screen>
      <ScreenBody>{children}</ScreenBody>
      <BottomNav />
    </Screen>
  );
};

export default AppLayout;
