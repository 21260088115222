import { List, ListItem } from "@material-ui/core";
import { SquircleAvatar } from "helpers/styles/avatars";
import styled from "styled-components";

export const SubRoutes = styled(List)`
  .MuiTypography-root {
    font-family: Poppins;
    font-weight: 600;
  }
`;

export const SubRoute = styled(ListItem).attrs({
  button: true
})``;

export const SubRouteAvatar = styled(SquircleAvatar)<{ color: string }>`
  background-color: ${({ color }) => color};
`;
