function getStaticMapUrl(
  lat: number,
  lon: number,
  zoom: number,
  width: number,
  height: number
) {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lon}&zoom=${zoom}&size=${width}x${height}&scale=1&format=JPEG&key=AIzaSyD9VVqyU_b_ko9UH8q-O22_vUe9iwU6uhA&style=element:labels%7Cvisibility:off&style=element:labels.icon%7Cvisibility:off&style=element:labels.text%7Cgamma:0.00%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x000000%7Csaturation:36%7Clightness:40%7Cvisibility:off&style=element:labels.text.stroke%7Ccolor:0x000000%7Clightness:16%7Cvisibility:off&style=feature:administrative%7Celement:geometry.fill%7Ccolor:0x000000%7Clightness:20&style=feature:administrative%7Celement:geometry.stroke%7Ccolor:0x000000%7Clightness:17%7Cweight:1.2&style=feature:administrative.country%7Celement:labels.text%7Cvisibility:off&style=feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.neighborhood%7Cvisibility:off&style=feature:landscape%7Celement:geometry%7Ccolor:0x000000%7Clightness:20&style=feature:poi%7Celement:geometry%7Ccolor:0x000000%7Clightness:21&style=feature:road%7Celement:geometry.fill%7Ccolor:0xffffff&style=feature:road%7Celement:geometry.stroke%7Cvisibility:off&style=feature:road.arterial%7Celement:geometry%7Ccolor:0x000000%7Clightness:18&style=feature:road.highway%7Celement:geometry.fill%7Ccolor:0x000000%7Clightness:17&style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0x000000%7Clightness:29%7Cweight:0.2&style=feature:road.local%7Celement:geometry%7Ccolor:0x000000%7Clightness:16&style=feature:road.local%7Celement:geometry.fill%7Ccolor:0x606060%7Cvisibility:on&style=feature:road.local%7Celement:geometry.stroke%7Cvisibility:off&style=feature:transit%7Celement:geometry%7Ccolor:0x000000%7Clightness:19&style=feature:water%7Celement:geometry%7Ccolor:0x404040%7Clightness:17&size=480x360`;
}

export default getStaticMapUrl;
