import { StepConnector, Stepper } from "@material-ui/core";
import { lighten } from "polished";
import styled from "styled-components";

export const Root = styled(Stepper)`
  background-color: transparent;
  padding: ${({ theme }) => theme.spacing(2)}px;
  z-index: 10 !important;

  & .MuiStepLabel-root {
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 1;
    position: relative;

    & .MuiStepLabel-label {
      margin-top: ${({ theme }) => theme.spacing(1)}px;
      color: ${({ theme }) => theme.palette.background.default};
      opacity: 0.2;
      text-transform: capitalize;

      &.MuiStepLabel-active {
        opacity: 1;
        font-weight: 600;
      }
    }
  }
`;

export const Connector = styled(StepConnector)`
  top: 18px;
  z-index: 0;

  & .MuiStepConnector-line {
    opacity: 0.2;
    border-top-width: 4px;
    border-color: ${({ theme }) => theme.palette.background.default};
  }

  &.MuiStepConnector-completed,
  &.MuiStepConnector-active {
    .MuiStepConnector-line {
      opacity: 0.8;
    }
  }
`;

export const Icon = styled.div<{
  completed: boolean;
  active: boolean;
}>`
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing(1)}px;
  opacity: ${({ completed, active }) => (completed || active ? 1 : 0.2)};
  box-shadow: ${({ active, theme }) =>
    active
      ? `0 2px 16px -4px ${lighten(0.6, theme.palette.secondary.main)}`
      : "none"};

  svg {
    display: block;
  }
`;
