import { Step, StepLabel } from "@material-ui/core";
import CompanyIcon from "@material-ui/icons/BusinessCenterOutlined";
import ProfileIcon from "@material-ui/icons/PersonOutlineRounded";
import AppIcon from "@material-ui/icons/SettingsOutlined";
import React from "react";

import { Connector, Icon, Root } from "./styles";

const StepIcon = ({
  completed,
  active,
  icon
}: {
  completed: boolean;
  active: boolean;
  icon: number;
}) => {
  const icons = [
    <ProfileIcon key={0} />,
    <CompanyIcon key={1} />,
    <AppIcon key={2} />
  ];

  return (
    <Icon completed={completed} active={active}>
      {icons[icon - 1]}
    </Icon>
  );
};

interface Props {
  value: number;
}

export const OnboardingStepper = ({ value }: Props) => {
  return (
    <Root alternativeLabel connector={<Connector />} activeStep={value}>
      <Step key="profile">
        <StepLabel StepIconComponent={StepIcon}>profile</StepLabel>
      </Step>
      <Step key="company">
        <StepLabel StepIconComponent={StepIcon}>company</StepLabel>
      </Step>
      <Step key="app">
        <StepLabel StepIconComponent={StepIcon}>app</StepLabel>
      </Step>
    </Root>
  );
};
