import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBackRounded";
import { Header, Wrapper } from "components/Profile";
import { useSelector } from "helpers/redux/useSelector";
import { SquircleAvatar } from "helpers/styles/avatars";
import React from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
import { capitalize } from "voca";

export const Company = () => {
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);
  const focusedCompanyId =
    profile.companiesIds.length === 1 ? profile.companiesIds[0] : "";

  useFirestoreConnect([
    {
      collection: "companyData",
      doc: focusedCompanyId,
      storeAs: "focusedCompanyData"
    }
  ]);

  const companyData = useSelector(
    state => state.firestore.data.focusedCompanyData
  );

  // TODO: clean up types to avoid this
  if (!companyData) return null;
  if (!profile.details) return null;

  return (
    <Wrapper>
      <AppBar elevation={1} position="static">
        <Toolbar>
          <Link to="/">
            <IconButton edge="start">
              <BackIcon />
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
      <Header>
        {/* <SquircleAvatar size={96} src={auth.photoURL || ""} /> */}
        <Typography variant="h4">{capitalize(companyData.name)}</Typography>
      </Header>
    </Wrapper>
  );
};
