import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBackOutlined";
import { BottomSheet } from "components/BottomSheet";
import { ContentfulBackdrop } from "components/ContentfulBackdrop";
import GooglePlaceField from "components/GooglePlaceField";
import { Field, Form, Formik, FormikErrors } from "formik";
import getStaticMapUrl from "helpers/apis/getStaticMapUrl";
import storeRemotePicture from "helpers/apis/storeRemotePicture";
import { useSelector } from "helpers/redux/useSelector";
import { useSnackbar } from "notistack";
import React from "react";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { capitalize } from "voca";

import formSchema, { LocationValues } from "./formSchema";

function validateLocation(values: LocationValues) {
  const errors = {} as FormikErrors<LocationValues>;

  if (values.name === "") errors.name = "No name provided.";
  //   else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
  //     errors.email = "Invalid email address.";

  return errors;
}

const NewLocation = () => {
  const auth = useSelector(state => state.firebase.auth);

  const firestore = useFirestore();
  const { enqueueSnackbar } = useSnackbar();
  const match = useRouteMatch<{
    id: string;
  }>();
  const routerLocation = useLocation();
  const history = useHistory();
  const isEditing = routerLocation.pathname.indexOf("new") === -1;

  useFirestoreConnect([
    {
      collection: "companyData",
      doc: auth.uid,
      subcollections: [{ collection: "locations" }],
      storeAs: "locations",
      orderBy: [["name", "asc"]]
    }
  ]);

  const location = useSelector(
    state => state.firestore.data.locations?.[match.params.id]
  );

  async function submitLocation(value: LocationValues) {
    const newDocRef = firestore
      .collection("companyData")
      .doc(auth.uid)
      .collection("locations")
      .doc();

    const squareThumbnailUrl = await storeRemotePicture(
      getStaticMapUrl(
        value.address.geometry.lat,
        value.address.geometry.lon,
        18,
        256,
        256
      ),
      `locations/${auth.uid}/${newDocRef.id}`,
      `${auth.uid}_${newDocRef.id}_thumbnail`
    );
    const smallHeroUrl = await storeRemotePicture(
      getStaticMapUrl(
        value.address.geometry.lat,
        value.address.geometry.lon,
        16,
        512,
        288
      ),
      `locations/${auth.uid}/${newDocRef.id}`,
      `${auth.uid}_${newDocRef.id}_small_hero`
    );
    const bigHeroUrl = await storeRemotePicture(
      getStaticMapUrl(
        value.address.geometry.lat,
        value.address.geometry.lon,
        16,
        1024,
        320
      ),
      `locations/${auth.uid}/${newDocRef.id}`,
      `${auth.uid}_${newDocRef.id}_big_hero`
    );

    console.log({
      ...value,
      name: value.name.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      created_at: new Date(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      last_update: new Date(),
      staticMaps: {
        thumbnail: squareThumbnailUrl,
        smallHero: smallHeroUrl,
        bigHero: bigHeroUrl
      }
    });

    try {
      await newDocRef.set({
        ...value,
        name: value.name.toLowerCase(),
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_at: new Date(),
        // eslint-disable-next-line @typescript-eslint/camelcase
        last_update: new Date(),
        staticMaps: {
          thumbnail: squareThumbnailUrl,
          smallHero: smallHeroUrl,
          bigHero: bigHeroUrl
        }
      });

      enqueueSnackbar(
        `${capitalize(
          value.name
        )} was successfully added to your locations list!`
      );
      history.goBack();
    } catch (e) {
      console.error(e);
    }
  }

  async function updateLocation(value: LocationValues) {
    try {
      await firestore
        .collection("companyData")
        .doc(auth.uid)
        .collection("locations")
        .doc(match.params.id)
        .update({
          ...value,
          name: value.name.toLowerCase(),
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_update: new Date()
        });

      enqueueSnackbar(`${capitalize(value.name)} was successfully updated!`);
      history.goBack();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <ContentfulBackdrop>
      <AppBar elevation={0}>
        <Toolbar>
          <IconButton edge="start" onClick={() => history.goBack()}>
            <BackIcon color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Formik<LocationValues>
        initialValues={(location || formSchema.initialValues) as LocationValues}
        validate={validateLocation}
        onSubmit={isEditing ? updateLocation : submitLocation}
        enableReinitialize
      >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched
        }) => {
          const canSubmit =
            routerLocation.pathname.indexOf("new") >= 0 ? touched.name : true;

          return (
            <BottomSheet textAlign="left" noHeader>
              <Container maxWidth="md">
                <Typography variant="h5">Location data</Typography>
                <Form>
                  <Grid container spacing={2} justify="center">
                    {formSchema.fields.map(field => (
                      <Grid
                        key={field.id}
                        item
                        xs={field.halved ? 6 : 12}
                        sm={field.halved ? 3 : 6}
                        md={field.halved ? 6 : 12}
                      >
                        {field.type === "address" ? (
                          isEditing || (
                            <Field name={field.id}>
                              {() => (
                                <GooglePlaceField
                                  id={field.id}
                                  name={field.id}
                                  label={field.label}
                                  value={values[field.id]}
                                  // TODO: Better definition for this boy
                                  onChange={(value: any) => {
                                    setFieldValue(field.id, {
                                      ...value,
                                      geometry: {
                                        lat: value.geometry.location.lat(),
                                        lon: value.geometry.location.lng()
                                      }
                                    });
                                  }}
                                  onTouch={() => setFieldTouched(field.id)}
                                  variant="filled"
                                  error={Boolean(errors[field.id])}
                                  helperText={errors[field.id]}
                                  autoComplete="new-password"
                                  fullWidth
                                />
                              )}
                            </Field>
                          )
                        ) : (
                          <TextField
                            id={field.id}
                            name={field.id}
                            label={field.label}
                            value={values[field.id]}
                            type={field.type}
                            error={Boolean(errors[field.id])}
                            helperText={errors[field.id]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="filled"
                            InputLabelProps={{
                              shrink: Boolean(values[field.id])
                            }}
                            fullWidth
                          />
                        )}
                      </Grid>
                    ))}
                    <Grid item xs={12} sm={6} md={12}>
                      <Box marginTop={2}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={!canSubmit}
                        >
                          {isSubmitting ? (
                            <CircularProgress color="secondary" size={24} />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </Container>
            </BottomSheet>
          );
        }}
      </Formik>
    </ContentfulBackdrop>
  );
};

export default NewLocation;
