import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import WelcomeSprite from "assets/illustrations/welcome.png";
import { BottomSheet } from "components/BottomSheet";
import { ContentfulBackdrop } from "components/ContentfulBackdrop";
import { OnboardingStepper } from "components/OnboardingStepper";
import { useSelector } from "helpers/redux/useSelector";
import React, { useState } from "react";
import { isLoaded, useFirestore } from "react-redux-firebase";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";

import {
  AppForm,
  AppValues,
  CompanyForm,
  CompanyValues,
  ProfileForm,
  ProfileValues
} from "./forms";
import { Congratulations } from "./styles";

export const Onboarding = () => {
  const [activeForm, setActiveForm] = useState(0);
  const firestore = useFirestore();
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);
  const history = useHistory();
  const { pathname } = useLocation();

  const steps = ["profile", "company", "app"];

  function submitProfile(values: ProfileValues) {
    firestore.update(
      { collection: "users", doc: auth.uid },
      { details: values }
    );
    history.push("/onboarding/company");
    setActiveForm(activeForm + 1);
  }

  function submitCompany(values: CompanyValues) {
    firestore.update(
      { collection: "users", doc: auth.uid },
      { company: values }
    );
    history.push("/onboarding/app");
    setActiveForm(activeForm + 1);
  }

  function submitApp(values: AppValues) {
    firestore.update(
      { collection: "users", doc: auth.uid },
      { app: values, onboarded: true }
    );
    history.push("/onboarding/success");
  }

  function FormSelector() {
    const hasProfile = Boolean(profile.details);
    const hasCompany = Boolean(profile.company);
    const hasApp = Boolean(profile.app);

    return (
      <Switch>
        <Route path="/onboarding/profile">
          {hasProfile ? (
            <Redirect to="/onboarding/company" />
          ) : (
            <ProfileForm onSubmit={submitProfile} />
          )}
        </Route>
        <Route path="/onboarding/company">
          {hasCompany ? (
            <Redirect to="/onboarding/app" />
          ) : (
            <CompanyForm onSubmit={submitCompany} />
          )}
        </Route>
        <Route path="/onboarding/app">
          {hasApp ? (
            <Redirect to="/onboarding/success" />
          ) : (
            <AppForm onSubmit={submitApp} />
          )}
        </Route>
      </Switch>
    );
  }

  return (
    <ContentfulBackdrop>
      <Switch>
        <Route path="/onboarding/success">
          <Congratulations>
            <img src={WelcomeSprite} alt="welcome illustration" />
            <Typography variant="h2">You did it!</Typography>
            <Typography>
              Let&apos;s get you started, welcome to patron!
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              component={Link}
              to="/"
            >
              Let&apos;s get started!
            </Button>
          </Congratulations>
        </Route>
        <Route path="/onboarding">
          <OnboardingStepper
            value={steps.indexOf(pathname.slice(pathname.lastIndexOf("/") + 1))}
          />
          <BottomSheet scrollable>
            {!isLoaded(profile) ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="50vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <hgroup>
                  <Typography variant="h3">Hello there!</Typography>
                  <Typography variant="h6">
                    Let&apos;s get to know each other, we need some information
                    about you!
                  </Typography>
                </hgroup>
                <FormSelector />
              </>
            )}
          </BottomSheet>
        </Route>
      </Switch>
    </ContentfulBackdrop>
  );
};
