import { SquircleAvatar } from "helpers/styles/avatars";
import { alignCenter } from "helpers/styles/flex";
import { transparentize } from "polished";
import styled from "styled-components";

export const Wrapper = styled.div`
  .MuiAppBar-root {
    background-color: transparent;
  }

  header {
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;

    p {
      opacity: 0.6;
    }
  }

  h5 {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const Header = styled.div<{
  image?: string;
}>`
  min-height: 288px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  background-image: url(${props => props.image});
  background-size: cover;

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 50vh;
  }
`;

export const AddressBadge = styled.div`
  ${alignCenter};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) =>
    transparentize(0.9, theme.palette.primary.main)};

  ${SquircleAvatar} {
    margin-right: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const Contacts = styled.div`
  height: 80px;
  display: flex;
  align-items: center;

  & .MuiDivider-root {
    height: 25%;
  }

  & .MuiIconButton-root {
    height: 56px;
    width: 56px;
    margin: 8px;
  }
`;

export const Nothing = styled.div`
  ${alignCenter};
  padding: ${({ theme }) => theme.spacing(4)}px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) =>
    transparentize(0.9, theme.palette.primary.main)};

  *:only-child {
    margin: 0;
  }
`;
