import LocalizedStrings from "react-localization";

import appBarStrings from "./strings/appBar";
import bottomBarStrings from "./strings/bottomBar";
import clientsStrings from "./strings/clients";
import employeesStrings from "./strings/employees";
import general from "./strings/general";
import homeStrings from "./strings/home";
import locationsStrings from "./strings/locations";

type StringBlock =
  | "general"
  | "appBar"
  | "bottomBar"
  | "home"
  | "employees"
  | "clients"
  | "locations";

type AvailableLocales = "it" | "en";

const strings = new LocalizedStrings({
  en: {
    general: general.en,
    appBar: appBarStrings.en,
    bottomBar: bottomBarStrings.en,
    home: homeStrings.en,
    employees: employeesStrings.en,
    clients: clientsStrings.en,
    locations: locationsStrings.en
  },
  it: {
    general: general.it,
    appBar: appBarStrings.it,
    bottomBar: bottomBarStrings.it,
    home: homeStrings.it,
    employees: employeesStrings.it,
    clients: clientsStrings.it,
    locations: locationsStrings.it
  }
});

const useStrings = (block: StringBlock, locale: AvailableLocales): any => {
  strings.setLanguage(locale);
  return strings[block];
};

export { useStrings };
export default strings;
