import {
  AppBar,
  Avatar,
  Box,
  Container,
  Dialog,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import BackIcon from '@material-ui/icons/ChevronLeftRounded';
import NextIcon from '@material-ui/icons/ChevronRightRounded';
import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded';
import { DatePicker } from '@material-ui/pickers';
import { useStrings } from 'data/strings';
import groupArray from 'group-array';
import { useSelector } from 'helpers/redux/useSelector';
import getCurrencySymbol from 'helpers/strings/getCurrencySymbol';
import { SquircleAvatar } from 'helpers/styles/avatars';
import { Grow } from 'helpers/styles/flex';
import { InsetCard } from 'helpers/styles/layout';
import { NakedLink } from 'helpers/styles/utilites';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect
} from 'react-redux-firebase';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { EmployeePresence } from 'redux/types';
import { capitalize } from 'voca';

import {
  EmployeePresenceBox,
  EmployeePresences,
  HomeScreen,
  LocationCard,
  LocationContainer,
  Locations
} from './styles';

const Home = () => {
  const homeStrings = useStrings('home', 'it');

  const [isPickingDate, setIsPickingDate] = useState(false);
  const [appbarMenuTarget, setAppbarMenuTarget] = useState<HTMLElement | null>(
    null
  );
  const [focusedDay, setFocusedDay] = useState(moment());
  const [
    focusedPresenceElement,
    setFocusedPresenceElement
  ] = useState<HTMLElement | null>(null);
  const [
    focusedPresence,
    setFocusedPresence
  ] = useState<EmployeePresence | null>(null);
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useHistory();
  const match = useRouteMatch<{ dateId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const currencySymbol = getCurrencySymbol(profile.app?.currency || '');

  useEffect(() => {
    history.replace(`/home/${focusedDay.format('YYYYMMDD')}`);
    firestore
      .collection('companyData')
      .doc(auth.uid)
      .collection('presences')
      .doc(focusedDay.format('YYYYMMDD'))
      .set(
        {
          date: focusedDay.startOf('D').toDate()
        },
        { merge: true }
      );
  }, [focusedDay]);

  useFirestoreConnect([
    {
      collection: 'companyData',
      doc: auth.uid,
      storeAs: 'locations',
      subcollections: [
        {
          collection: 'locations'
        }
      ]
    },
    {
      collection: 'companyData',
      doc: auth.uid,
      storeAs: 'employees',
      subcollections: [
        {
          collection: 'employees'
        }
      ]
    },
    {
      collection: 'companyData',
      doc: auth.uid,
      storeAs: 'focusedDayPresences',
      subcollections: [
        {
          collection: 'presences',
          doc: match.params.dateId
        }
      ]
    }
  ]);

  function handlePresenceClick(
    event: React.MouseEvent<HTMLButtonElement>,
    presence: EmployeePresence
  ) {
    setFocusedPresenceElement(event.currentTarget);
    setFocusedPresence(presence);
  }

  function handlePresenceClose() {
    setFocusedPresenceElement(null);
    setFocusedPresence(null);
  }

  const allPresences: EmployeePresence[] = useSelector(
    (state) => state.firestore.data.focusedDayPresences?.presences
  );

  function handleDeletePresence() {
    firestore
      .collection('companyData')
      .doc(auth.uid)
      .collection('presences')
      .doc(match.params.dateId)
      .update({
        presences: allPresences.filter(
          (presence) =>
            !(
              presence.placeId === focusedPresence?.placeId &&
              presence.employeeId === focusedPresence?.employeeId
            )
        )
      });

    enqueueSnackbar(
      `${focusedPresence?.employeeData?.name}'s presence has been removed.`
    );
    handlePresenceClose();
  }

  const focusedDayPresences = useSelector((state) => {
    return Object.entries(
      groupArray<EmployeePresence>(
        allPresences?.map((presence: EmployeePresence) => ({
          ...presence,
          employeeData: state.firestore.data.employees[presence.employeeId]
        })),
        'placeId'
      )
    ).map(([placeId, locationPresences]) => ({
      location: state.firestore.data.locations[placeId],
      presences: locationPresences
    }));
  });

  return (
    <HomeScreen>
      <AppBar elevation={0} position='sticky'>
        <Toolbar>
          <Grow />
          <IconButton
            edge='end'
            onClick={(event) => setAppbarMenuTarget(event?.currentTarget)}
          >
            <SquircleAvatar src={auth.photoURL ?? ''} size={32}>
              {capitalize(profile.details?.name[0])}
            </SquircleAvatar>
          </IconButton>
        </Toolbar>
        <Menu
          id='settings-menu'
          anchorEl={appbarMenuTarget}
          open={Boolean(appbarMenuTarget)}
          onClose={() => setAppbarMenuTarget(null)}
        >
          <NakedLink to='/company'>
            <MenuItem dense>Company</MenuItem>
          </NakedLink>
          <NakedLink to='/me'>
            <MenuItem dense>Profilo</MenuItem>
          </NakedLink>
          <MenuItem dense onClick={() => firebase.logout()}>
            Logout
          </MenuItem>
        </Menu>
      </AppBar>
      <Container>
        <Typography variant='h2'>
          {homeStrings.greeting} {profile.details?.name}!
        </Typography>
        <Box display='flex' alignItems='center'>
          <Typography variant='h6'>
            {`${focusedDay.format(
              'ddd DD MMM'
            )} • ${currencySymbol}${allPresences?.reduce(
              (dailyTotal, presence) =>
                dailyTotal + presence.rate * presence.rate_amount,
              0
            ) ?? '--'}`}
          </Typography>
          <Grow />
          <IconButton size='small' onClick={() => setIsPickingDate(true)}>
            <CalendarIcon />
          </IconButton>
          <IconButton
            size='small'
            onClick={() => setFocusedDay(focusedDay.clone().subtract(1, 'd'))}
          >
            <BackIcon />
          </IconButton>
          <IconButton
            size='small'
            onClick={() => setFocusedDay(focusedDay.clone().add(1, 'd'))}
            disabled={focusedDay.isSame(moment(), 'd')}
          >
            <NextIcon />
          </IconButton>
        </Box>
        <Box marginTop={3}>
          {/* <Typography variant="h5">My locations</Typography> */}
          {!focusedDayPresences.length && (
            <InsetCard>
              <Box
                textAlign='center'
                padding={1}
                paddingTop={3}
                paddingBottom={3}
              >
                <Typography variant='h4'>
                  {homeStrings.placeholders.locations.title}
                </Typography>
                <Typography>
                  {homeStrings.placeholders.locations.body}
                </Typography>
              </Box>
            </InsetCard>
          )}
          <Locations>
            {focusedDayPresences.map((locationPresence) => (
              <LocationContainer key={locationPresence.location.name}>
                <LocationCard>
                  <header>
                    <Typography variant='h6'>
                      {capitalize(locationPresence.location.name)} •{' '}
                      {currencySymbol}
                      {locationPresence.presences.reduce(
                        (locationDailyTotal, presence) =>
                          locationDailyTotal +
                          presence.rate * presence.rate_amount,
                        0
                      )}
                    </Typography>
                  </header>
                  <EmployeePresences>
                    <Grid container>
                      {locationPresence.presences.map(
                        (presence: EmployeePresence) => (
                          <Grid key={presence.employeeId} item xs={4}>
                            <EmployeePresenceBox
                              onClick={(event) =>
                                handlePresenceClick(event, presence)
                              }
                            >
                              <SquircleAvatar
                                size={64}
                                src={presence.employeeData?.photo}
                              >
                                {presence.employeeData?.photo ||
                                  capitalize(presence.employeeData?.name)[0]}
                              </SquircleAvatar>
                              <Typography>
                                <strong>
                                  {capitalize(presence.employeeData?.name)}
                                </strong>
                              </Typography>
                              <Typography>
                                <small>
                                  {presence.rate * presence.rate_amount}
                                  {profile.app?.currency &&
                                    getCurrencySymbol(profile.app?.currency)}
                                </small>
                              </Typography>
                            </EmployeePresenceBox>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </EmployeePresences>
                </LocationCard>
              </LocationContainer>
            ))}
            <Box paddingLeft={16} />
          </Locations>
          <Menu
            id='presence-menu'
            anchorEl={focusedPresenceElement}
            keepMounted
            open={Boolean(focusedPresenceElement)}
            onClose={handlePresenceClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <MenuItem dense onClick={handleDeletePresence}>
              <DeleteIcon />
              <Box marginLeft={1}>
                <Typography> Remove</Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Container>

      <Dialog open={isPickingDate} onClose={() => setIsPickingDate(false)}>
        <DatePicker
          disableFuture
          orientation='portrait'
          variant='static'
          openTo='date'
          value={focusedDay}
          onChange={console.log}
          onAccept={(date) => {
            setFocusedDay(moment(date?.toISOString()));
            setIsPickingDate(false);
          }}
        />
      </Dialog>
    </HomeScreen>
  );
};

export default Home;
