import { ButtonBase } from "@material-ui/core";
import { SquircleAvatar } from "helpers/styles/avatars";
import { lighten } from "polished";
import styled from "styled-components";

export const HomeScreen = styled.div`
  .MuiAppBar-root {
    background-color: transparent;
  }

  .MuiContainer-root {
    padding-top: ${({ theme }) => theme.spacing(1)}px;
  }

  .MuiTypography-h6 {
    margin-bottom: 0;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 320px;
    width: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12c0-.622-.095-1.221-.27-1.785A5.982 5.982 0 0 0 10 12c1.67 0 3.182-.683 4.27-1.785A5.998 5.998 0 0 0 14 12h2a4 4 0 0 1 4-4V6c-1.67 0-3.182.683-4.27 1.785C15.905 7.22 16 6.622 16 6c0-.622-.095-1.221-.27-1.785A5.982 5.982 0 0 0 20 6V4a4 4 0 0 1-4-4h-2c0 .622.095 1.221.27 1.785A5.982 5.982 0 0 0 10 0C8.33 0 6.818.683 5.73 1.785 5.905 1.22 6 .622 6 0H4a4 4 0 0 1-4 4v2c1.67 0 3.182.683 4.27 1.785A5.998 5.998 0 0 1 4 6c0-.622.095-1.221.27-1.785A5.982 5.982 0 0 1 0 6v2a4 4 0 0 1 4 4h2zm-4 0a2 2 0 0 0-2-2v2h2zm16 0a2 2 0 0 1 2-2v2h-2zM0 2a2 2 0 0 0 2-2H0v2zm20 0a2 2 0 0 1-2-2h2v2zm-10 8a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%239C92AC' fill-opacity='.5' fill-rule='evenodd'/%3E%3C/svg%3E");
    mask-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
    pointer-events: none;
    z-index: -1;
  }
`;

export const Locations = styled.div`
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  margin: 0 -${({ theme }) => theme.spacing(2)}px;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const LocationCard = styled.div`
  display: inline-block;
  padding: ${({ theme }) => theme.spacing(2)}px;
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  min-height: 320px;
  width: 100%;
  background-color: ${({ theme }) => lighten(0.8, theme.palette.primary.main)};

  header {
    margin-bottom: ${({ theme }) => theme.spacing()}px;
  }
`;

export const LocationContainer = styled.div`
  scroll-snap-align: start;
  flex-basis: 320px;
  flex-shrink: 0;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  padding-right: ${({ theme }) => theme.spacing(1)}px;
`;

export const EmployeePresences = styled.div`
  margin: 0 -${({ theme }) => theme.spacing(1)}px;
`;

export const EmployeePresenceBox = styled(ButtonBase)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 1.5}px;

  ${SquircleAvatar} {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }

  p {
    margin-bottom: 0;
    line-height: 1rem;
    font-size: 0.8rem;
  }
`;
