import {
  List,
  ListItem,
  ListItemText,
  Switch,
  Tabs,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import { useStrings } from 'data/strings';
import { useSelector } from 'helpers/redux/useSelector';
import { SquircleAvatar, SquircleListItemAvatar } from 'helpers/styles/avatars';
import { ListView, PageHeader } from 'helpers/styles/layout';
import React, { useState } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { Location, LocationWithId } from 'redux/types';
import { capitalize } from 'voca';

const Locations = () => {
  const locationsStrings = useStrings('locations', 'it');
  const auth = useSelector((state) => state.firebase.auth);
  const [isArchiveMode, setIsArchiveMode] = useState(false);
  const [query, setQuery] = useState('');

  useFirestoreConnect([
    {
      collection: 'companyData',
      doc: auth.uid,
      subcollections: [{ collection: 'locations' }],
      storeAs: 'locations',
      orderBy: [['name', 'asc']]
    }
  ]);

  const locations: LocationWithId[] =
    useSelector((state) => {
      const locationRecords: [string, Location][] = Object.entries(
        state.firestore.data.locations || {}
      );
      return locationRecords.map((record) => ({
        id: record[0],
        ...record[1]
      }));
    }) || [];

  return (
    <ListView>
      <PageHeader>
        <Typography variant='h2'>{locationsStrings.title}</Typography>
      </PageHeader>
      <Toolbar disableGutters style={{ justifyContent: 'space-between' }}>
        <TextField
          size='small'
          variant='outlined'
          placeholder='Search...'
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Switch
          color='primary'
          checked={isArchiveMode}
          onChange={() => setIsArchiveMode(!isArchiveMode)}
        />
      </Toolbar>
      <List>
        {locations
          .filter(({ isArchived, name }) => {
            return (
              (isArchived ?? false) === isArchiveMode &&
              name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
            );
          })
          .map(({ id, photo, staticMaps, name, address }) => (
            <ListItem key={id} button component={Link} to={`/locations/${id}`}>
              <SquircleListItemAvatar size={56}>
                <SquircleAvatar src={photo || staticMaps?.thumbnail} alt=''>
                  {photo || capitalize(name)[0]}
                </SquircleAvatar>
              </SquircleListItemAvatar>
              <ListItemText
                primary={capitalize(name)}
                secondary={capitalize(address.name)}
              />
            </ListItem>
          ))}
      </List>
    </ListView>
  );
};

export default Locations;
