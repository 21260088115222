import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddRounded";
import BackIcon from "@material-ui/icons/ArrowBackRounded";
import { useSelector } from "helpers/redux/useSelector";
import { SquircleAvatar, SquircleListItemAvatar } from "helpers/styles/avatars";
import { ListView, PageHeader } from "helpers/styles/layout";
import { ColorTypography, FixedFab } from "helpers/styles/utilites";
import React from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { Link, useHistory } from "react-router-dom";
import { DBClient, LocalClient } from "types/entities";
import { capitalize } from "voca";

export const Clients = () => {
  const history = useHistory();
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);

  useFirestoreConnect([
    {
      collection: "companyData",
      doc: auth.uid,
      subcollections: [{ collection: "clients" }],
      storeAs: "clients",
      orderBy: [["lastName", "asc"]]
    }
  ]);

  const clients: DBClient[] =
    useSelector(state => {
      const clientDocs: [string, LocalClient][] = Object.entries(
        state.firestore.data.clients || {}
      );
      return clientDocs.map(record => ({
        id: record[0],
        ...record[1]
      }));
    }) || [];

  return (
    <>
      <Toolbar>
        <BackIcon onClick={() => history.goBack()} />
        <Box marginLeft={1}>
          <ColorTypography variant="h5" style={{ marginBottom: 0 }}>
            Clients
          </ColorTypography>
        </Box>
      </Toolbar>
      {clients.length === 0 ? (
        <div>Nessun cliente</div>
      ) : (
        <List disablePadding>
          {clients.map(({ id, lastName, firstName, mainEmail, mainPhone }) => (
            <ListItem
              key={id}
              button
              component={Link}
              to={`/finances/clients/${id}`}
            >
              <SquircleListItemAvatar size={56}>
                <SquircleAvatar>{capitalize(lastName)[0]}</SquircleAvatar>
              </SquircleListItemAvatar>
              <ListItemText
                primary={`${capitalize(lastName)} ${capitalize(firstName)}`}
                secondary={mainEmail || mainPhone}
              />
            </ListItem>
          ))}
        </List>
      )}
      <FixedFab color="primary" onClick={() => history.push("/new/clients")}>
        <AddIcon />
      </FixedFab>
    </>
  );
};
