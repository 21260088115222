import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { Field, FieldProps, Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { DatabaseAbsence, LocalAbsence } from 'types/entities';

interface Props {
  active: boolean;
  editingAbsence: DatabaseAbsence | null;
  onClose: () => void;
  onSubmit: (value: LocalAbsence) => void;
}

const DatePickerField = ({ field, form, ...other }: FieldProps) => {
  const currentError = form.errors[field.name];

  return (
    <DatePicker
      clearable
      name={field.name}
      value={field.value}
      format='DD/MM/YYYY'
      helperText={currentError}
      error={Boolean(currentError)}
      onChange={(date) => form.setFieldValue(field.name, date, false)}
      {...other}
    />
  );
};

export const EmployeeAbsence = ({
  active,
  editingAbsence,
  onSubmit,
  onClose
}: Props) => {
  return (
    <Dialog open={active} onClose={onClose}>
      <Formik
        initialValues={
          editingAbsence
            ? {
                type: editingAbsence.type,
                from: moment(editingAbsence.from.toDate()),
                to: moment(editingAbsence.to.toDate()),
                reason: editingAbsence.reason,
                notes: editingAbsence.notes
              }
            : {
                type: 'other',
                from: moment(),
                to: moment(),
                reason: '',
                notes: ''
              }
        }
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <DialogTitle id='form-dialog-title'>{`${
              editingAbsence ? 'Modifica' : 'Aggiungi'
            } assenza`}</DialogTitle>
            <DialogContent>
              <Field
                name='from'
                label='Da'
                inputVariant='outlined'
                margin='normal'
                component={DatePickerField}
                fullWidth
              />
              <Field
                name='to'
                label='A'
                inputVariant='outlined'
                margin='normal'
                component={DatePickerField}
                fullWidth
              />
              <Field
                name='type'
                label='Tipo'
                type='text'
                variant='outlined'
                margin='normal'
                as={TextField}
                select
                fullWidth
              >
                <MenuItem value='holidays'>Ferie</MenuItem>
                <MenuItem value='health'>Malattia</MenuItem>
                <MenuItem value='other'>Altro</MenuItem>
              </Field>
              <Field
                name='reason'
                label='Motivo'
                type='text'
                variant='outlined'
                margin='normal'
                as={TextField}
                fullWidth
              />
              <Field
                name='notes'
                label='Note'
                type='text'
                variant='outlined'
                margin='normal'
                as={TextField}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color='primary'>
                Annulla
              </Button>
              <Button type='submit' color='primary'>
                {`Salva ${editingAbsence ? 'modifiche' : 'assenza'}`}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
