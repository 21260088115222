import { Paper } from "@material-ui/core";
import styled, { css } from "styled-components";

export const Sheet = styled(Paper)`
  flex-shrink: 1;
  border-radius: ${({ theme }) => theme.spacing(4)}px
    ${({ theme }) => theme.spacing(4)}px 0 0;
  padding: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(5)}px;

  hgroup {
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    border-radius: ${({ theme }) => theme.spacing(4)}px;
  }

  form {
    margin-top: ${({ theme }) => theme.spacing(5)}px;
  }
`;

export const SheetWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 24px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
`;

export const Wrapper = styled.div<{
  scrollable?: boolean;
  noHeader?: boolean;
  topSpacing?: number;
}>`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 10;

  ${props =>
    props.noHeader
      ? css`
          ${Sheet} {
            form {
              margin-top: ${({ theme }) => theme.spacing(3)}px;
            }
          }
        `
      : ""};

  ${props =>
    !props.scrollable
      ? css`
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          overflow: auto;
        `
      : css`
          ${({ theme }) => theme.breakpoints.down("sm")} {
            padding-top: ${({ topSpacing }) =>
              topSpacing ? `${topSpacing}px` : "50vh"};
          }

          ${Sheet} {
            min-height: ${({ topSpacing }) =>
              topSpacing ? `calc(100vh - ${topSpacing}px)` : "50vh"};

            ${({ theme }) => theme.breakpoints.up("md")} {
              margin-top: auto;
              margin-bottom: 24px;
            }
          }
        `};
`;
