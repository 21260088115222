import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Slider,
  TextField
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { EmployeePresence } from 'redux/types';

interface PresenceDialogProps
  extends Omit<Omit<DialogProps, 'onClose'>, 'onSubmit'> {
  onClose: () => void;
  presence: EmployeePresence;
  onSubmit: (value: EmployeePresence) => void;
}

const PresenceDialog: React.FC<PresenceDialogProps> = ({
  onClose,
  presence,
  onSubmit,
  ...rest
}: PresenceDialogProps) => {
  return (
    <Dialog fullWidth {...rest}>
      <Formik<EmployeePresence> initialValues={presence} onSubmit={onSubmit}>
        <Form>
          <DialogTitle>Modifica Presenza</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name='rate'
                  label='Paga'
                  type='number'
                  variant='outlined'
                  margin='normal'
                  as={TextField}
                  fullWidth
                />
              </Grid>
              {presence.rate_type === 'hour' && (
                <Grid item xs={12}>
                  <Field
                    name='quantity'
                    min={1}
                    max={24}
                    step={1}
                    valueLabelDisplay='auto'
                    as={Slider}
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Annulla</Button>
            <Button variant='contained' color='primary' type='submit'>
              Salva
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default PresenceDialog;
