import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBackRounded";
import { Header, Wrapper } from "components/Profile";
import { useSelector } from "helpers/redux/useSelector";
import { SquircleAvatar } from "helpers/styles/avatars";
import React from "react";
import { Link } from "react-router-dom";
import { capitalize } from "voca";

export const Profile = () => {
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);

  // TODO: clean up types to avoid this
  if (!profile.details) return null;

  return (
    <Wrapper>
      <AppBar elevation={1} position="static">
        <Toolbar>
          <Link to="/">
            <IconButton edge="start">
              <BackIcon />
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
      <Header>
        <SquircleAvatar size={96} src={auth.photoURL || ""} />
        <Typography variant="h4">{`${capitalize(
          profile.details.name
        )} ${capitalize(profile.details.surname)}`}</Typography>
      </Header>
    </Wrapper>
  );
};
