import { CircularProgress } from "@material-ui/core";
import React from "react";

import { Overlay } from "./styles";

export const LoadingOverlay = () => {
  return (
    <Overlay>
      <CircularProgress color="secondary" />
    </Overlay>
  );
};
