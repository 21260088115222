import {
  AppBar,
  Box,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import BackIcon from '@material-ui/icons/ArrowBackRounded';
import EditIcon from '@material-ui/icons/EditOutlined';
import PlaceIcon from '@material-ui/icons/PlaceOutlined';
import { BottomSheet } from 'components/BottomSheet';
import getTotalPresenceHours from 'helpers/calculations/getTotalPresenceHours';
import { useSelector } from 'helpers/redux/useSelector';
import { kFormatter } from 'helpers/strings/kFormatter';
import { SquircleAvatar } from 'helpers/styles/avatars';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Employee, Location, PresenceWithId } from 'redux/types';
import { Expense } from 'types/entities';
import { capitalize } from 'voca';

import LocationDashboard from './components/LocationDashboard';
import LocationExpenses from './components/LocationExpenses';
import LocationPresences from './components/LocationPresences';
import Placeholder from './Placeholder';
import { AddressBadge, Header, Nothing, Wrapper } from './styles';

export const LocationDetails = () => {
  const history = useHistory();
  const match = useRouteMatch<{
    id: string;
  }>();
  const [activeTab, setActiveTab] = useState(0);
  const auth = useSelector((state) => state.firebase.auth);

  const firestore = useFirestore();
  useFirestoreConnect([
    {
      collection: 'companyData',
      doc: auth.uid,
      subcollections: [{ collection: 'locations' }],
      storeAs: 'locations',
      orderBy: [['name', 'asc']]
    },
    {
      collection: 'companyData',
      doc: auth.uid,
      subcollections: [{ collection: 'employees' }],
      storeAs: 'employees',
      orderBy: [['name', 'asc']]
    },
    {
      collection: 'companyData',
      doc: auth.uid,
      subcollections: [{ collection: 'presences' }],
      storeAs: 'presences',
      orderBy: [['date', 'desc']]
    },
    {
      collection: 'companyData',
      doc: auth.uid,
      subcollections: [
        {
          collection: 'locations',
          doc: match.params.id,
          subcollections: [{ collection: 'expenses' }]
        }
      ],
      storeAs: 'expenses',
      orderBy: [['date', 'desc']]
    }
  ]);

  const theme = useTheme();
  const showBigHero = useMediaQuery(theme.breakpoints.up('md'));

  const location: Location = useSelector(
    (state) => state.firestore.data.locations?.[match.params.id]
  );
  const employees: Record<string, Employee> = useSelector(
    (state) => state.firestore.data.employees
  );
  const expenses: Record<string, Expense> = useSelector(
    (state) => state.firestore.data.expenses
  );
  const locationPresenceDates: PresenceWithId[] =
    useSelector((state) =>
      (state.firestore.ordered.presences as PresenceWithId[])?.map(
        (presence) => ({
          id: presence.id,
          date: presence.date,
          presences:
            presence.presences?.filter(
              (pres) => pres.placeId === match.params.id
            ) || []
        })
      )
    )?.filter((presence) => presence.presences.length > 0) || [];

  const tabs = useMemo(
    () => [
      <LocationDashboard
        key='dashboard'
        location={location}
        expenses={expenses}
        locationPresenceDates={locationPresenceDates}
      />,
      <LocationPresences
        key='employees'
        employees={employees}
        locationPresenceDates={locationPresenceDates}
      />,
      <LocationExpenses key='expenses' expenses={expenses} />
    ],
    [location, locationPresenceDates, employees]
  );

  return (
    <Wrapper>
      <AppBar elevation={0} position='fixed'>
        <Toolbar>
          <IconButton
            edge='start'
            color='secondary'
            onClick={() => history.goBack()}
          >
            <BackIcon />
          </IconButton>
          <Box flexGrow={1} />
          <IconButton
            color='secondary'
            onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              if (!confirm('Vuoi veramente archiviare questo luogo?')) return;

              const locationRef = firestore
                .collection('companyData')
                .doc(auth.uid)
                .collection('locations')
                .doc(match.params.id);

              locationRef.update({
                isArchived: !(location.isArchived ?? false)
              });
            }}
          >
            <ArchiveIcon />
          </IconButton>
          <IconButton
            edge='end'
            color='secondary'
            onClick={() => history.push(`/edit/locations/${match.params.id}`)}
          >
            <EditIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {location ? (
        <>
          <Header
            image={
              showBigHero
                ? location.staticMaps.bigHero
                : location.staticMaps.smallHero
            }
          />
          <BottomSheet topSpacing={256} textAlign='left' scrollable>
            <Box display='flex' gridRowGap={2} justifyContent='space-between'>
              <Typography variant='h4'>{capitalize(location.name)}</Typography>
              {location.isArchived && (
                <Chip color='secondary' label='Archiviato' />
              )}
            </Box>
            <Box marginTop={2}>
              <Tabs
                value={activeTab}
                onChange={(e, value) => setActiveTab(value)}
              >
                <Tab label='Dashboard' />
                <Tab label='Presenze' />
                <Tab label='Spese' />
              </Tabs>
              <Grid container spacing={4}>
                {tabs[activeTab]}
              </Grid>
            </Box>
          </BottomSheet>
        </>
      ) : (
        <Placeholder />
      )}
    </Wrapper>
  );
};
