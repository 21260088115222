import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 16px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: 1px;

  .MuiContainer-root {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }

  .MuiToolbar-root {
    flex-direction: row-reverse;
    min-height: 40px;

    .MuiIconButton-root {
      padding: ${({ theme }) => theme.spacing(1)}px;

      &:last-child {
        margin-right: ${({ theme }) => theme.spacing(-1)}px;
      }
    }
  }

  .MuiTextField-root {
    .MuiFilledInput-root {
      border-radius: 12px;

      .MuiFilledInput-inputMarginDense {
        padding-left: ${({ theme }) => theme.spacing(1.5)}px;
      }
    }

    .MuiAutocomplete-inputRoot,
    .MuiInputLabel-filled {
      padding-left: 0;
    }
  }

  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiExpansionPanel {
    &-root {
      background-color: ${({ theme }) => theme.palette.grey[100]};
      margin-top: ${({ theme }) => theme.spacing(1)}px;
      border-radius: 12px;

      &:first-of-type {
        margin-top: ${({ theme }) => theme.spacing(2)}px;
      }

      &::before {
        content: none;
      }

      &.Mui-expanded {
        margin-top: ${({ theme }) => theme.spacing(1)}px;
        margin-bottom: 0;

        .MuiExpansionPanel {
          &Summary-root {
            p:last-of-type {
              opacity: 0;
            }
          }

          &Details-root {
            padding-bottom: ${({ theme }) => theme.spacing(2)}px;
          }
        }
      }
    }

    &Summary-root,
    &Details-root {
      padding: 0 ${({ theme }) => theme.spacing(2)}px;
    }

    &Summary {
      &-root {
        min-height: 32px;
        height: 32px;

        .MuiTypography-root:first-child {
          margin-right: auto;
        }

        .MuiIconButton-root {
          margin-right: -16px;
        }
      }

      &-content {
        margin: 0;
      }
    }
  }
`;

export const MainContent = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
