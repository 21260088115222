import { useStrings } from "data/strings";

/* eslint-disable @typescript-eslint/camelcase */
export interface EmployeeValues {
  photo: string | null;
  name: string;
  surname: string;
  specialization: string | null;
  email: string | null;
  phone: string | null;
  rate: number | null;
  rate_type: "hour" | "day" | "week" | "month" | "year" | null;
}

export enum fieldKey {
  photo = "photo",
  name = "name",
  surname = "surname",
  specialization = "specialization",
  email = "email",
  phone = "phone",
  rate = "rate",
  rate_type = "rate_type"
}

const { rateTypes } = useStrings("general", "it");
const { new: newEmployeeStrings } = useStrings("employees", "it");

const formSchema = {
  initialValues: {
    photo: null,
    name: "",
    surname: "",
    email: null,
    phone: null,
    rate: null,
    rate_type: null
  },
  fields: [
    {
      id: "name",
      label: newEmployeeStrings.name,
      type: "text",
      required: true
    },
    {
      id: "surname",
      label: newEmployeeStrings.surname,
      type: "text",
      required: true
    },
    {
      id: "specialization",
      label: newEmployeeStrings.specialization,
      type: "text",
      required: false
    },
    {
      id: "rate_type",
      label: newEmployeeStrings.rateType,
      type: "select",
      options: [
        { value: "hour", label: rateTypes.hour },
        { value: "day", label: rateTypes.day },
        { value: "week", label: rateTypes.week },
        { value: "month", label: rateTypes.month },
        { value: "year", label: rateTypes.year }
      ],
      halved: true,
      required: true
    },
    {
      id: "rate",
      label: newEmployeeStrings.rate,
      type: "number",
      halved: true,
      required: true
    },
    {
      id: "photo",
      label: newEmployeeStrings.photo,
      type: "avatar",
      required: false
    },
    {
      id: "email",
      label: newEmployeeStrings.email,
      type: "email",
      required: true
    },
    {
      id: "phone",
      label: newEmployeeStrings.phone,
      type: "tel",
      required: true
    }
  ]
} as {
  initialValues: EmployeeValues;
  fields: {
    id: fieldKey;
    label: string;
    type: "text" | "number" | "email" | "tel" | "select" | "avatar";
    required: boolean;
    halved?: boolean;
    options?: {
      value: string;
      label: string;
    }[];
  }[];
};

export default formSchema;
