export const formFields = {
  profile: [
    {
      id: "name",
      label: "Your name",
      type: "text",
      required: true
    },
    {
      id: "surname",
      label: "Your surname",
      type: "text",
      required: true
    },
    {
      id: "email",
      label: "Email",
      type: "email",
      required: true
    },
    {
      id: "tel",
      label: "Phone",
      type: "tel",
      required: false
    }
  ],
  company: [
    {
      id: "name",
      label: "Name",
      type: "text",
      required: true
    },
    {
      id: "address",
      label: "Main Address",
      type: "address",
      required: true
    },
    {
      id: "phone",
      label: "Phone number",
      type: "tel",
      required: true
    },
    {
      id: "email",
      label: "Email",
      type: "email",
      required: true
    },
    {
      id: "companyId",
      label: "Company Id",
      type: "text",
      required: true
    },
    {
      id: "fax",
      label: "Fax",
      type: "text",
      required: false
    },
    {
      id: "website",
      label: "Website",
      type: "text",
      required: false
    }
  ],
  app: [
    {
      id: "currency",
      label: "Currency",
      type: "select",
      required: true
    }
  ]
} as {
  profile: {
    id: "name" | "surname" | "email" | "phone";
    label: string;
    type: "text" | "email" | "tel";
    required: boolean;
  }[];
  company: {
    id:
      | "name"
      | "address"
      | "phone"
      | "email"
      | "companyId"
      | "fax"
      | "website";
    label: string;
    type: "text" | "email" | "tel" | "address";
    required: boolean;
  }[];
  app: {
    id: "currency";
    label: string;
    type: "select";
    required: boolean;
  }[];
};
