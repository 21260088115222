import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Employee, PresenceWithId } from 'redux/types';

import { Nothing, Wrapper } from '../styles';

const LocationPresences = ({
  employees,
  locationPresenceDates
}: {
  employees: Record<string, Employee>;
  locationPresenceDates: PresenceWithId[];
}) => {
  return (
    <Grid item xs={12} sm={6} md={12}>
      {locationPresenceDates.length > 0 ? (
        <List disablePadding dense>
          {locationPresenceDates.map((presenceDate) => (
            <>
              <ListSubheader key={presenceDate.id} disableSticky disableGutters>
                {moment(presenceDate.date.toDate()).format('DD/MM/YYYY')}
              </ListSubheader>
              {presenceDate.presences.map((employeePresence) => {
                const presenceEmployee = employees[employeePresence.employeeId];

                return (
                  <ListItem key={employeePresence.employeeId} disableGutters>
                    <ListItemText
                      primary={`${presenceEmployee.name} ${presenceEmployee.surname}`}
                    />
                    <Typography>
                      <strong>
                        {`${employeePresence.rate *
                          employeePresence.rate_amount}€`}
                      </strong>
                    </Typography>
                  </ListItem>
                );
              })}
            </>
          ))}
        </List>
      ) : (
        <Nothing>
          <Typography variant='h6'>No past work</Typography>
        </Nothing>
      )}
    </Grid>
  );
};

export default LocationPresences;
