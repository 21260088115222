import {
  Box,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import ExpandIcon from "@material-ui/icons/ExpandMoreRounded";
import Autocomplete, {
  AutocompleteChangeReason,
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import { FieldProps } from "formik";
import React, { ChangeEvent, useState } from "react";
import { QuoteItem, QuoteItemTemplate, Tax } from "types/entities";

import { MainContent, Wrapper } from "./styles";

const initialValues = {
  name: "",
  cost: 0,
  quantity: 0,
  discountType: "percentage" as const,
  discount: 0,
  taxes: []
};

export const initialItemValues = {
  ...initialValues,
  type: "item" as const
};

export const initialTaskValues = {
  ...initialValues,
  type: "task" as const
};

type Props = FieldProps<QuoteItem> & {
  templates: QuoteItemTemplate[];
  taxes: Tax[];
  onDelete: () => void;
};

const QuoteRecordField: React.FC<Props> = ({
  templates,
  taxes,
  form: { setTouched, setFieldValue },
  field: { name, value, onChange, onBlur },
  onDelete
}: Props) => {
  const filterOptions = createFilterOptions<QuoteItemTemplate>({
    matchFrom: "any",
    stringify: option => option.name
  });

  const fieldCommons = {
    InputLabelProps: { shrink: true },
    fullWidth: true,
    variant: "filled" as const,
    size: "small" as const,
    onChange,
    onBlur
  };

  const fieldSubTotal = value.cost * value.quantity;
  const fieldDiscount =
    value.discountType === "percentage"
      ? (fieldSubTotal / 100) * value.discount
      : fieldSubTotal - value.discount;
  const discountedSubtotal = fieldSubTotal - fieldDiscount;
  const fieldTax = (discountedSubtotal / 100) * value.taxes[0];
  const fieldTotal = discountedSubtotal + fieldTax;

  return (
    <Wrapper>
      <Toolbar>
        <IconButton onClick={() => onDelete()}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Toolbar>
      <Container>
        <MainContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Autocomplete<QuoteItemTemplate>
                freeSolo
                size="small"
                filterOptions={filterOptions}
                onInputChange={(_, fieldValue) =>
                  setFieldValue(`${name}.name`, fieldValue)
                }
                onChange={(
                  _: ChangeEvent<{}>,
                  autocompleteValue: QuoteItemTemplate | null
                ) => {
                  setFieldValue(`${name}.template`, autocompleteValue?.id);
                  setFieldValue(`${name}.name`, autocompleteValue?.name);
                  setFieldValue(
                    `${name}.description`,
                    autocompleteValue?.description
                  );
                  setFieldValue(`${name}.cost`, autocompleteValue?.cost);
                  setFieldValue(`${name}.quantity`, 1);
                }}
                onBlur={() => setTouched({ [`${name}.name`]: true })}
                options={templates.filter(({ type }) => type === value.type)}
                getOptionLabel={option => option.name}
                renderOption={option => option.name}
                renderInput={params => (
                  <TextField {...fieldCommons} {...params} label="Nome" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...fieldCommons}
                name={`${name}.description`}
                value={value.description}
                label="Descrizione"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                {...fieldCommons}
                name={`${name}.cost`}
                value={value.cost}
                label="Costo"
                type="number"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                {...fieldCommons}
                name={`${name}.quantity`}
                value={value.quantity}
                label="Qtà"
                type="number"
              />
            </Grid>
            <Grid item xs={4}>
              <Typography align="right">
                <small>Subtotale:</small>
              </Typography>
              <Typography align="right">
                <strong>{fieldSubTotal.toFixed(2)}€</strong>
              </Typography>
            </Grid>
          </Grid>
        </MainContent>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandIcon />}>
            <Typography>
              <strong>Sconto</strong>
            </Typography>
            <Typography>
              {value.discountType === "percentage"
                ? `${value.discount}% • ${fieldDiscount.toFixed(2)}€`
                : `${fieldDiscount.toFixed(2) || 0}€`}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  {...fieldCommons}
                  name={`${name}.discountType`}
                  value={value.discountType}
                  label="Tipo"
                  select
                >
                  <MenuItem value="percentage">percentuale</MenuItem>
                  <MenuItem value="fixed">fisso</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  {...fieldCommons}
                  name={`${name}.discount`}
                  value={value.discount}
                  label="Sconto"
                  type="number"
                />
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">
                  <small>Scontato:</small>
                </Typography>
                <Typography align="right">
                  <strong>{discountedSubtotal.toFixed(2)}€</strong>
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandIcon />}>
            <Typography>
              <strong>Tasse</strong>
            </Typography>
            <Typography>
              {`${value.taxes[0]}% • ${fieldTax.toFixed(2)}€`}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  {...fieldCommons}
                  name={`${name}.taxes[0]`}
                  value={value.taxes[0]}
                  label="Tassa"
                  select
                >
                  {taxes.map(tax => (
                    <MenuItem
                      key={`${tax.name} • ${tax.percentage}`}
                      value={tax.percentage}
                    >{`${tax.name} • ${tax.percentage}%`}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <Typography align="right">
                  <small>Lordo:</small>
                </Typography>
                <Typography align="right">
                  <strong>{fieldTotal.toFixed(2)}€</strong>
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Container>
    </Wrapper>
  );
};

export default QuoteRecordField;
