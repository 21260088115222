import styled from "styled-components";

export const SimpleIconWrapper = styled.div<{
  inButton?: boolean;
}>`
  width: 24px;
  margin-right: ${({ theme, inButton }) =>
    inButton ? theme.spacing(1.5) : 0}px;

  & > div {
    color: inherit !important;
    height: 24px;
  }

  svg {
    fill: inherit;
  }
`;
