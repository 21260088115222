import { createStore } from "redux";

import rootReducer from "./reducer";

const initialState = {};

/* eslint-disable no-underscore-dangle */
export default createStore(
  rootReducer,
  initialState,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);
/* eslint-enable */
